export interface State {
  provider: string;
  retUrl?: string;
  invitationToken?: string;
  invitationEmail?: string;
  originFullUrl?: string;
}

export function decodeState(state: string): State {
  try {
    if (state) {
      return JSON.parse(decodeURIComponent(state));
    }
  } catch (e) {
    console.error("Error reading SSO state");
  }
  return { provider: "builtfirst", retUrl: "" };
}
