import React, { ReactNode } from "react";
import { Button, ButtonProps, styled, useTheme } from "@mui/material";

const TextButton = styled("span")(({ theme }) => ({
  display: "none",
  fontSize: theme.typography.h6.fontSize,
  fontWeight: "bold",
  [theme.breakpoints.up("md")]: {
    display: "unset",
  },
}));

interface Props {
  textColor?: string;
}

const StyledButton = styled(Button)<Props>(({ theme, textColor }) => ({
  "&:hover": {
    backgroundColor: "transparent",
  },
  [theme.breakpoints.up("md")]: {
    padding: 0,
  },
  textTransform: "initial",
  zIndex: theme.zIndex.appBar,
  color: textColor || `${theme.palette.text.default} !important`,
}));

export const BackButton = styled(
  React.forwardRef(
    (
      props: ButtonProps &
        Props & {
          children?: ReactNode;
        },
      ref: React.Ref<HTMLButtonElement>,
    ) => {
      const theme = useTheme();
      const ArrowBackIcon = theme.icons.arrowBack;
      return (
        <StyledButton
          ref={ref}
          {...props}
          disableRipple
          startIcon={<ArrowBackIcon fill={props.textColor} />}
          variant="text"
        >
          <TextButton>{theme.settings.backButtonText}</TextButton>
        </StyledButton>
      );
    },
  ),
)<Props>(() => ({}));
