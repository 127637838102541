import { useContext } from "react";
import { UserCtx } from "../../app/UserProviderCtx";
import { SSR } from "../../helpers/environment";
import {
  clearAuthenticationTokens,
  defaultFetcher,
  HTTPResponse,
  isResponseUnauthorized,
  fetcherWithHeaders,
} from "../HTTPClient";
import { JSONAPIDocument, serializers } from "../Serializer";
import { APIV3 } from "../HTTPClientV3";
import { Configuration } from "./configuration";
import { ServerError } from "../ServerError";
import { GuestUser } from "./guestUser";
import { setAuthorizationHeaders } from "../../app/Authorization";
import { INVITATION_TOKEN } from "../constants";

export interface User extends GuestUser {
  organization?: {
    allowedInMarketplace: boolean;
    name: string;
    logoUrl: string;
    website: string;
    id: string;
  };
  invitedBanner: boolean;
  sellerBanner: boolean;
  partnerOwned: boolean;
  confirmed: boolean;
  community?: Configuration;
  pictureUrl: string;
  passwordSet: string;
  id: string;
}

export interface TokenList {
  jwt?: string;
  accessToken?: string;
  invite?: string;
}

export interface SignUpData {
  fullName: string;
  firstName?: string;
  lastName?: string;
  signUpOrganizationName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  code?: string;
  productSlug?: string;
}
export interface ResetPasswordData {
  password: string;
  passwordConfirmation: string;
  redirectUrl?: string;
  provider?: string;
}

export interface JwtPayload {
  token: string;
  cid: string;
  provider?: string;
  screenshot?: string;
}

const basev3 = "user";
const resendEmailUrl = `users/confirmations/resend`;
const passwordUrl = `users/password`;

async function fetcher(
  request: () => Promise<HTTPResponse<JSONAPIDocument<User>>>,
) {
  if (!SSR) {
    try {
      const response = await request();
      if (isResponseUnauthorized(response)) {
        clearAuthenticationTokens();
        throw new ServerError(
          String("Unauthorized request, user token is not valid."),
          response.url,
        );
      }
      const json = await response.json();
      const user = serializers.user.deserialize(json);
      return user;
    } catch (e) {
      console.error(e); // TODO: handle error (to share handle error logic with defaultFetcher)
    }
  }
}
// TODO: BLOCK-PR it should not pass the PR, this method should only be called at userStorage so we avoid dupplicate requests
export function signUp(payload: SignUpData) {
  const request = () =>
    APIV3.post<JSONAPIDocument<SignUpData>>(basev3, {
      body: JSON.stringify(serializers.token.serialize(payload)),
    });
  return {
    json: async () => {
      const response = await fetcherWithHeaders(request, serializers.token);
      setAuthorizationHeaders(response.headers);
      return response;
    },
    fallbackKey: basev3,
  };
}

export function updateUser(payload: SignUpData, invitationToken?: string) {
  const headers = new Headers();

  if (invitationToken) {
    headers.set(INVITATION_TOKEN, invitationToken);
  }

  const request = () =>
    APIV3.put<JSONAPIDocument<SignUpData>>(basev3, {
      body: JSON.stringify(serializers.user.serialize(payload)),
      headers,
    });
  return {
    json: async () => defaultFetcher(request, serializers.user),
    fallbackKey: basev3,
  };
}

export function resetPassword(payload: ResetPasswordData) {
  const headers = new Headers();
  const request = () =>
    APIV3.put<JSONAPIDocument<null>>(passwordUrl, {
      body: JSON.stringify(serializers.password.serialize(payload)),
      headers,
    });
  return {
    json: async () => defaultFetcher(request, serializers.user),
    fallbackKey: passwordUrl,
  };
}

export function resendVerificationEmail(email: string) {
  const request = () =>
    APIV3.post<JSONAPIDocument<User>>(resendEmailUrl, {
      headers: { uid: `${email}` },
    });

  return {
    json: async () => defaultFetcher(request, serializers.user),
    fallbackKey: resendEmailUrl,
  };
}

export function forgotPassword(email: string) {
  const request = () =>
    APIV3.post<JSONAPIDocument<null>>(passwordUrl, {
      body: JSON.stringify(serializers.user.serialize({ email })),
    });

  return {
    json: async () => defaultFetcher(request, serializers.user),
    fallbackKey: passwordUrl,
  };
}

const userRelatedEntities = ["community", "organization"];
export async function getUserByJwt({
  token,
  cid,
  provider,
  screenshot,
}: JwtPayload) {
  const headers = new Headers();
  const request = () =>
    APIV3.post<JSONAPIDocument<User>>(
      `${basev3}/jwt?include=${userRelatedEntities.toString()}`,
      {
        body: JSON.stringify(
          serializers.token.serialize({
            cid,
            token,
            provider: provider || "builtfirst",
            screenshot,
          }),
        ),
        headers,
      },
    );
  const response = await fetcherWithHeaders(request, serializers.user);
  setAuthorizationHeaders(response.headers);
  return response;
}

export function getUser() {
  const url = `${basev3}?include=${userRelatedEntities.toString()}`;
  const request = () => APIV3.get<JSONAPIDocument<User>>(url);
  return {
    json: async () => fetcher(request),
    fallbackKey: url,
  };
}

export function useUser() {
  const { user, loading, jwtAuthenticationError } = useContext(UserCtx);
  return {
    loadingUser: loading,
    user,
    jwtAuthenticationError,
  };
}
