export enum ErrorCode {
  communityRequestPending = "community_request_pending",
  communityRequestRejected = "community_request_rejected",
  googleVerify = "google_verify_error",
}

export class ServerError {
  private message: string;

  private code?: number | string;

  constructor(message: string, url: string, code?: number | string) {
    this.message = message;
    this.code = code;
    console.error("Error", message, url, code);
  }

  getMessage() {
    return this.message;
  }

  getCode() {
    return this.code;
  }
}
