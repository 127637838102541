import { useRouter } from "next/router";
import { SSR } from "../helpers/environment";
import { buildUrlWithQueryParams, useQueryParams } from "./QueryParams";
import { useConfiguration } from "./api/configuration";
import { localStorageWrapper } from "./LocalStorage";
import { getRandomKey } from "../helpers/formatter";
import { SSO_REDIRECT_PARAM } from "./constants";
import type { State } from "../helpers/sso";

export function getNonceKey(provider: string) {
  return `sso-nonce-${provider}`;
}

function useIdTokenUrl(
  baseUrl: string,
  state: State,
  clientId?: string,
  defaultClientId?: boolean,
) {
  let idTokenUrl = "";
  const { asPath } = useRouter();
  const {
    invitation_token: invitationToken,
    invitation_email: invitationEmail,
  } = useQueryParams();
  if (!SSR && clientId) {
    if (defaultClientId) {
      state.originFullUrl = window.location.href;
    } else if (asPath) {
      state.retUrl = asPath || "";
    }
    if (invitationToken) {
      state.invitationToken = invitationToken;
      state.invitationEmail = invitationEmail;
    }

    const nonceKey = getNonceKey(state?.provider);
    let nonce = localStorageWrapper.getItem(nonceKey);
    if (!nonce) {
      nonce = getRandomKey();
      localStorageWrapper.setItem(nonceKey, nonce);
    }
    const redirectUri = defaultClientId
      ? process.env.NEXT_PUBLIC_SSO_DEFAULT_REDIRECT
      : `${window.location.origin}?${SSO_REDIRECT_PARAM}=true`;
    idTokenUrl = buildUrlWithQueryParams(baseUrl, {
      response_type: "id_token",
      login_hint: invitationEmail,
      client_id: clientId,
      state: encodeURIComponent(JSON.stringify(state)),
      scope: "openid email profile",
      redirect_uri: `${redirectUri}`,
      nonce,
    });
  }
  return { idTokenUrl };
}

export function useGoogleSSO() {
  const { configuration } = useConfiguration();
  const defaultClientId = configuration?.ssoSetting.useDefaultGoogleClient;
  const clientId = defaultClientId
    ? process.env.NEXT_PUBLIC_SSO_DEFAULT_GOOGLE_CLIENT_ID
    : configuration?.ssoSetting.googleClientId;

  const provider = "google";
  const { idTokenUrl } = useIdTokenUrl(
    "https://accounts.google.com/o/oauth2/v2/auth",
    {
      provider,
    },
    clientId,
    defaultClientId,
  );
  return { googleUrl: idTokenUrl, googleProvider: provider };
}

export function useMicrosoftSSO() {
  const { configuration } = useConfiguration();
  const defaultClientId = configuration?.ssoSetting.useDefaultMicrosoftClient;
  const clientId = defaultClientId
    ? process.env.NEXT_PUBLIC_SSO_DEFAULT_MICROSOFT_CLIENT_ID
    : configuration?.ssoSetting.microsoftClientId;

  const provider = "microsoft";
  const { idTokenUrl } = useIdTokenUrl(
    "https://login.microsoftonline.com/common/oauth2/v2.0/authorize",
    {
      provider,
    },
    clientId,
    defaultClientId,
  );
  return { microsoftUrl: idTokenUrl, microsoftProvider: provider };
}
