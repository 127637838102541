import { useContext, useMemo } from "react";
import { SWRConfig } from "swr";
import { NoSsr } from "@mui/material";
import { useCurrentRoute } from "../builder/mapping/page";
import { SSR, iframe } from "../helpers/environment";
import { SettingsConfiguration, ThemeLoader } from "../builder/Theme";
import { IntercomWrapper } from "./IntercomWrapper";
import Authorization from "./Authorization";
import { NotifyChanges } from "./NotifyChanges";
import NotFound from "../components/ui/NotFound";
import type { Fallback } from "../../pages/[[...page]]";
import { EditorCtx } from "../wysiwyg/EditorCtx";
import { communitiesFallbackKey } from "../dataAccess/api/configuration";
import { ThemeGlobalStyles } from "./ThemeGlobalStyles";
import type { Page } from "../wysiwyg/JsonThemeObject";

interface PageProps {
  settings: SettingsConfiguration;
}

interface Props {
  fallback: Fallback;
  PageComponent?: React.ComponentType<PageProps>;
}

export function getPageConfiguration(
  pages: Page[],
  route: ReturnType<typeof useCurrentRoute>,
) {
  return pages?.find(({ id }: { id: string }) => id === route?.id) || null;
}

export function App({ fallback, PageComponent }: Props) {
  const { liveConfig, isEditor, template } = useContext(EditorCtx);
  const currentRoute = useCurrentRoute();
  const pageConfiguration = useMemo(
    () =>
      getPageConfiguration(
        fallback[communitiesFallbackKey].data.publishedTemplate?.properties
          ?.pages,
        currentRoute,
      ),
    [fallback, currentRoute],
  );
  if (liveConfig) {
    if (template) {
      fallback[communitiesFallbackKey].data.publishedTemplate = { ...template };
    }
    fallback[communitiesFallbackKey].data.publishedTemplate.properties =
      liveConfig;
  }
  const pageProps: PageProps = useMemo(() => {
    if (isEditor) {
      const livePageConfiguration =
        liveConfig?.pages?.find(
          ({ id }: { id: string }) => id === currentRoute?.id,
        ) || null;
      return {
        settings: livePageConfiguration?.settings || {},
      };
    }
    return {
      settings: pageConfiguration?.settings || {},
    };
  }, [
    currentRoute?.id,
    isEditor,
    liveConfig?.pages,
    pageConfiguration?.settings,
  ]);

  return (
    <>
      <SWRConfig
        value={{
          fallback: fallback || undefined,
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
          // This must be false so that if there is nothing in cache a request is executed
          revalidateIfStale: false,
          // This must be undefined so avoid refeshing the page too many times
          revalidateOnMount: undefined,
        }}
      >
        {/* // TODO: There is a glitch with intercom user name, the IntercomProvider
         initialize before the user is fetched so we are not able to set the user
         name at the time the IntercomProvider initializes */}
        <ThemeLoader>
          <NoSsr>
            <ThemeGlobalStyles />
          </NoSsr>
          <IntercomWrapper>
            <Authorization>
              {iframe && !SSR && <NotifyChanges />}
              {PageComponent ? <PageComponent {...pageProps} /> : <NotFound />}
            </Authorization>
          </IntercomWrapper>
        </ThemeLoader>
      </SWRConfig>
    </>
  );
}
