type Callback = (...args: any[]) => void;
type EventsDefinition<Events> = { [K in keyof Events]: Callback };

export interface EventsDispatcher<Events extends EventsDefinition<Events>> {
  on<E extends keyof Events>(event: E, callback: Events[E]): this;
  dispatch<E extends keyof Events>(
    event: E,
    ...args: Parameters<Events[E]>
  ): void;
}

type EventsCallbacks<TEvents extends EventsDefinition<TEvents>> = {
  [K in keyof TEvents]?: ((...args: Parameters<TEvents[K]>) => void)[];
};

export class EventsDispatcherImpl<TEvents extends EventsDefinition<TEvents>>
  implements EventsDispatcher<TEvents>
{
  private eventsCallbacks: EventsCallbacks<TEvents> = {};

  on<E extends keyof TEvents>(event: E, callback: TEvents[E]): this {
    if (!this.eventsCallbacks[event]) {
      this.eventsCallbacks[event] = [];
    }
    if (
      !this.eventsCallbacks[event]?.find(
        (existingCallback) => existingCallback === callback,
      )
    ) {
      this.eventsCallbacks[event]?.push(callback);
    }
    return this;
  }

  off<E extends keyof TEvents>(event: E, callbackToRemove: TEvents[E]): this {
    this.eventsCallbacks[event] = this.eventsCallbacks[event]?.filter(
      (callback) => callback !== callbackToRemove,
    );
    return this;
  }

  dispatch<E extends keyof TEvents>(
    event: E,
    ...args: Parameters<TEvents[E]>
  ): void {
    this.eventsCallbacks[event]?.forEach((callback) => {
      callback(...args);
    });
  }
}
