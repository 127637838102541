export function setByPath<T extends Record<string, any>, K extends keyof T>(
  obj: T | null | undefined,
  propertyPath: string,
  value: T[K],
) {
  const keys = propertyPath.split(".") as (keyof T)[];

  if (!obj) {
    return obj;
  }

  keys.reduce((nestedObj, key, index) => {
    if (index === keys.length - 1) {
      nestedObj[key] = value;
    } else if (!nestedObj[key] || typeof nestedObj[key] !== "object") {
      nestedObj[key] = {} as T[keyof T];
    }

    return nestedObj[key];
  }, obj);

  return obj;
}

export function isNumber(value: any): boolean {
  return typeof value === "number" && !Number.isNaN(value);
}

type DeepMerge<T, U> = T extends object
  ? U extends object
    ? {
        [K in keyof (T & U)]: K extends keyof T
          ? K extends keyof U
            ? U[K] extends undefined
              ? T[K]
              : DeepMerge<T[K], U[K]>
            : T[K]
          : K extends keyof U
          ? U[K]
          : never;
      }
    : T
  : U;

export function deepMergeIgnoreUndefined<T extends object, U extends object>(
  target: T,
  source: U,
): DeepMerge<T, U> {
  const merged: any = { ...target };

  Object.keys(source).forEach((objectKey) => {
    const key = objectKey as keyof U & keyof T;
    if (source[key] !== undefined) {
      if (typeof target[key] === "object" && typeof source[key] === "object") {
        merged[key] = deepMergeIgnoreUndefined(
          target[key] as object,
          source[key] as object,
        );
      } else {
        merged[key] = source[key];
      }
    }
  });

  return merged as DeepMerge<T, U>;
}

export function deepEqual(objA: any, objB: any): boolean {
  if (objA === objB) {
    return true;
  }

  if (typeof objA !== "object" || typeof objB !== "object") {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  let areEqual = true;

  keysA.forEach((key) => {
    if (!keysB.includes(key) || !deepEqual(objA[key], objB[key])) {
      areEqual = false;
    }
  });

  return areEqual;
}
