import useSWRImmutable from "swr/immutable";

import { defaultFetcher } from "../HTTPClient";
import { APIV3 } from "../HTTPClientV3";
import { serializers, JSONAPIDocument } from "../Serializer";
import { ServerError } from "../ServerError";
import type { Template } from "./template";
import { buildUrlWithQueryParams } from "../QueryParams";

export const REVIEWS_ALLOWED_FEATURE_KEY = "reviews";

export enum RedemptionFlow {
  Email = "email",
  Classic = "classic",
}

interface File {
  legend: string;
  url: string;
  filename: string;
}

export interface EmbedUrl {
  id?: string;
  url: string;
}

// TODO: rename all this file to Marketplace
export interface Configuration {
  id: string;
  isOpenMarketplace: boolean;
  forceAccounts: boolean;
  redirectUrl: string;
  redemptionFlow: RedemptionFlow;
  publishedTemplate: Template;
  subdomain: string;
  customDomain: string;
  website: string;
  signUpLogoUrl: string;
  homePageLogoUrl: string;
  name: string;
  description: string;
  embedUrls: EmbedUrl[];
  tagline: string;
  ssoSetting: {
    forceJwtAuth: boolean;
    signInRedirectUrl: string;
    signUpRedirectUrl: string;
    googleClientId: string;
    microsoftClientId: string;
    useDefaultMicrosoftClient: boolean;
    useDefaultGoogleClient: boolean;
  };
  brandingSetting: {
    id: string;
    primaryColor: string;
    secondaryColor: string;
    bodyFontFamily: string;
    headerFontFamily: string;
    poweredByBuiltfirstLabel: boolean;
  };
  managerOrganization: {
    name: string;
    logoUrl: string;
  };
  mediaFiles: File[];
  fonts: File[];
  allowedFeatures: ("reviews" | "quotes")[];
}

export const communitiesFallbackKey = "/communities";

export async function pingServer() {
  return fetch(
    `${process.env.NEXT_PUBLIC_API_URL_V3}${communitiesFallbackKey.replace(
      "/",
      "",
    )}`,
  );
}

export function getConfiguration() {
  const url = buildUrlWithQueryParams(communitiesFallbackKey, {
    include:
      "manager_organization,media_files,fonts,embed_urls,branding_setting",
    "fields[community]": [
      "is_open_marketplace",
      "force_accounts",
      "redirect_url",
      "redemption_flow",
      "published_template",
      "subdomain",
      "custom_domain",
      "website",
      "sign_up_logo_url",
      "home_page_logo_url",
      "name",
      "description",
      "tagline",
      "media_files",
      "fonts",
      "embed_urls",
      "allowed_features",
      "sso_setting",
      "branding_setting",
      "manager_organization",
    ].join(),
    "fields[sso_setting]": [
      "force_jwt_auth",
      "sign_in_redirect_url",
      "sign_up_redirect_url",
      "google_client_id",
      "microsoft_client_id",
      "use_default_microsoft_client",
      "use_default_google_client",
    ].join(),
    "fields[branding_setting]": [
      "primary_color",
      "secondary_color",
      "body_font_family",
      "header_font_family",
      "powered_by_builtfirst_label",
    ].join(),
    "fields[manager_organization]": "name,logo_url",
  });
  const request = () => APIV3.get<JSONAPIDocument<Configuration>>(url);

  return {
    json: () => defaultFetcher(request, serializers.community),
    fallbackKey: communitiesFallbackKey,
  };
}

export function useConfiguration() {
  const { fallbackKey, json } = getConfiguration();
  const { data, error, isValidating, mutate } = useSWRImmutable(
    fallbackKey,
    json,
    {
      revalidateOnMount: false,
    },
  );
  if (!(error instanceof ServerError)) {
    return {
      loadingConfiguration: isValidating,
      setConfiguration: mutate,
      configuration: data?.data,
    };
  }
  return {
    loadingConfiguration: isValidating,
    setConfiguration: mutate,
    error,
  };
}
