import { FunctionComponent, useEffect } from "react";
import { Button, Link, Typography, styled } from "@mui/material";
import { iframe, isBuiltfirstSubdomain } from "../helpers/environment";
import { localStorageWrapper } from "../dataAccess/LocalStorage";
import { pingServer } from "../dataAccess/api/configuration";
import { StyledText, Title } from "../components/ui/RequestAccess";

const Main = styled("div")(() => ({
  display: "flex",
  height: "100vh",
  width: "100%",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const ButtonContainer = styled("div")(() => ({
  margin: "2rem 0",
  alignItems: "center",
  gap: "1rem",
  display: "flex",
}));

const StyledLink = styled(Link)(() => ({
  textDecoration: "none",
}));

const pollingTime = 30 * 1000;

const maintenanceKey = "maintenance";

export function isMaintenanceOn() {
  return localStorageWrapper.getItem(maintenanceKey) === "true";
}

export function turnMaintenanceOn() {
  localStorageWrapper.setItem(maintenanceKey, "true");
}

function turnMaintenanceOff() {
  localStorageWrapper.removeItem(maintenanceKey);
}

const Maintenance: FunctionComponent = () => {
  useEffect(() => {
    const interval = setInterval(async () => {
      const { ok } = await pingServer();
      if (ok) {
        turnMaintenanceOff();
        window.location.reload();
      }
    }, pollingTime);
    return () => clearInterval(interval);
  }, []);

  return (
    <Main>
      {/* This will change after wysiwyg implementation */}
      <style>
        {(() => `
        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }
        html, body, #root, #__next {
          height: ${!iframe ? "100%" : "auto"};
        }
      `)()}
      </style>
      <Title variant="h1">Site is temporarily unavailable.</Title>
      <StyledText variant="body1">
        Scheduled maintenance is currently in progress. Please check back soon.
        We apologize for any inconvenience.
      </StyledText>
      <ButtonContainer>
        <Button
          color="primary"
          variant="contained"
          onClick={() => window.location.reload()}
        >
          Refresh
        </Button>
        {isBuiltfirstSubdomain() && (
          <StyledLink href="https://builtfirst.com" target="_blank">
            <Typography>Builtfirst Team</Typography>
          </StyledLink>
        )}
      </ButtonContainer>
    </Main>
  );
};

export default Maintenance;
