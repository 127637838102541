import { useRouter } from "next/router";
import querystring from "querystring";
import { RouterPushOptions } from "../helpers/navigation";
import { adminKey } from "../wysiwyg/EditorCtx";
import { SSO_REDIRECT_PARAM } from "./constants";
import type { Device } from "../wysiwyg/EditorPage";
import { SSR } from "../helpers/environment";

export interface APIQueryParams {
  id: string;
  token: string;
  "fields[deal]": string;
  "fields[item]": string;
  "fields[sso_setting]": string;
  "fields[branding_setting]": string;
  "fields[community]": string;
  "fields[collection]": string;
  "fields[collection_header]": string;
  "fields[category]": string;
  "fields[manager_organization]": string;
  "fields[buyer_user]": string;
  "fields[buyer_organization]": string;
  "fields[form]": string;
  "fields[form_response]": string;
  "fields[quote_request]": string;
  "filter[seller]": string;
  "filter[category]": string;
  "filter[collection]": string;
  "filter[newest]": string;
  "filter[featured]": string;
  "filter[most_popular]": string;
  "filter[slug]": string;
  "fields[collections]": string;
  "fields[categories]": string;
  "fields[listing_features]": string;
  "fields[listing_plan_pricings]": string;
  "fields[media_files]": string;
  "fields[logo]": string;
  "fields[showcased_organizations]": string;
  "fields[helpful_links]": string;
  "fields[blurred_deal]": string;
  "fields[community_visibility]": string;
  "fields[install_now_button]": string;
  "page[size]": number;
  "page[number]": number;
  invite: string;
  sort:
    | "recommended"
    | "popularity"
    | "savings"
    | "organization.name"
    | "-recommended"
    | "-popularity"
    | "-savings"
    | "-organization.name";
  search: string;
  discounts_page: string;
  no_track_analytics: string;
  cid: string;
  response_type: string;
  client_id: string;
  scope: string;
  redirect_uri: string;
  state: string;
  nonce: string;
  login_hint: string;
  similar_by: string;
}

export interface QueryParams {
  [adminKey]: string;
  id: string;
  token: string;
  "editor-preview": string;
  "editor-device": Device;
  template_id: string;
  [SSO_REDIRECT_PARAM]: string;
  screenshot: string;
  no_redirect: string;
  manage_ret_url: string;
  partner_ids: string;
  category_ids: string;
  collection_ids: string;
  invite: string;
  sorting: "desc" | "asc" | "savings" | "popular" | "recommended";
  q: string;
  page_number: string;
  uid: string;
  client: string;
  email: string;
  retUrl: string;
  "access-token": string;
  jwt: string;
  cid: string;
  invitation_token: string;
  invitation_email: string;
  provider: string;
  preview_deal_id: string;
  include: string;
  created_for: "partner" | "seller";
  preview_action: "quote_submit";
  bf_redirect: string;
}

interface HashParams {
  id_token: string;
  state: string;
  error: string;
}

export type PickQueryParams<T extends keyof QueryParams> = Pick<QueryParams, T>;

export function useQueryParams(): Partial<QueryParams> {
  // TODO: if not yet, change this hook to be called on client or create a new one for client side
  // TODO: Review if we really need the "page" param here.
  const {
    query: { page, ...params },
  } = useRouter();
  return params;
}

// TODO: move to a different file
export function sortByPropertyName<T extends { [k: string]: any }>(
  unordered: T,
) {
  return Object.keys(unordered)
    .sort()
    .reduce((obj: any, key) => {
      obj[key] = unordered[key];
      return obj;
    }, {});
}

export function buildUrlWithQueryParams(
  url: string,
  queryParams?: Partial<APIQueryParams | QueryParams>,
) {
  const stringParams = querystring.stringify(queryParams);
  return stringParams ? `${url}?${stringParams}` : url;
}

export function buildThirdPartyUrlWithQueryParams<
  T extends Record<string, number | string | undefined>,
>(url: string, queryParams?: T) {
  return buildUrlWithQueryParams(url, queryParams);
}

export function useAddQueryParam() {
  const router = useRouter();
  return (
    paramKey: keyof QueryParams,
    paramValue: string,
    options?: RouterPushOptions,
    additionalOptions?: { replace: boolean },
  ) => {
    router.query = { ...router.query, [paramKey]: paramValue };
    router[additionalOptions?.replace ? "replace" : "push"](router, undefined, {
      ...options,
      scroll: false,
      shallow: true,
    });
  };
}

interface QueryParam {
  paramKey: keyof QueryParams;
  paramValue: string | null;
}

export function useAddQueryParams() {
  const router = useRouter();
  return (params: QueryParam[], options?: RouterPushOptions) => {
    const newParams = router.query;
    // Remove params
    params
      .filter((param) => param.paramValue === null)
      .forEach((toRemove) => delete newParams[toRemove.paramKey]);
    // Add new params
    params
      .filter((param) => param.paramValue !== null)
      .forEach((toSet) => {
        toSet.paramValue && (newParams[toSet.paramKey] = toSet.paramValue);
      });
    router.query = newParams;
    router.push(router, undefined, {
      ...options,
      scroll: false,
      shallow: true,
    });
  };
}

export function useHash() {
  const { asPath } = useRouter();
  const { hash } = new URL(asPath, "https://anyurl.org"); // URL needs any valid url to get hash
  return { hash };
}

export function useHashParams(): Partial<HashParams> {
  const { hash } = useHash();
  const hashParams = new URLSearchParams(hash.replace("#", ""));
  return Object.fromEntries(hashParams);
}

export function queryStringToArray(queryString?: string) {
  return queryString ? queryString.split(",") : [];
}

export function removeQueryParams(params: (keyof QueryParams)[]) {
  if (SSR) {
    return;
  }
  const url = new URL(window.location.href);
  params.forEach((param) => {
    url.searchParams.delete(param);
  });
  window.history.replaceState({}, "", url);
}
