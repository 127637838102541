import { SSR } from "../helpers/environment";
import { getRequestContext } from "../../pages/[[...page]]";
import { HTTPClient, HTTPResponse } from "./HTTPClient";
import { turnMaintenanceOn } from "../app/Maintenance";
import { localStorageWrapper } from "./LocalStorage";
import {
  ACCESS_TOKEN_KEY,
  ACCESS_TOKEN_CLIENT,
  ACCESS_TOKEN_EXPIRY,
  ACCESS_TOKEN_UID,
  DOWNTIME_STATUS_CODE,
} from "./constants";

export interface PaginationMeta {
  current: number;
  records?: number;
  next?: number;
  last?: number;
}

class HTTPClientV3 extends HTTPClient {
  protected host = process.env.NEXT_PUBLIC_API_URL_V3;

  protected async request<T>(
    url: string,
    config: RequestInit,
  ): Promise<HTTPResponse<T>> {
    const headers = new Headers(config?.headers);
    if (!SSR) {
      const accessToken = localStorageWrapper.getItem(ACCESS_TOKEN_KEY);
      const client = localStorageWrapper.getItem(ACCESS_TOKEN_CLIENT);
      const expiry = localStorageWrapper.getItem(ACCESS_TOKEN_EXPIRY);
      const uid = localStorageWrapper.getItem(ACCESS_TOKEN_UID);
      if (accessToken) {
        headers.set(ACCESS_TOKEN_KEY, accessToken);
      }
      if (client) {
        headers.set(ACCESS_TOKEN_CLIENT, client);
      }
      if (expiry) {
        headers.set(ACCESS_TOKEN_EXPIRY, expiry);
      }
      if (uid) {
        headers.set(ACCESS_TOKEN_UID, uid);
      }
    }

    const requestContext = getRequestContext();
    if (requestContext?.host) {
      headers.set("origin", `https://${requestContext.host}`);
    }
    headers.set("Content-Type", "application/json");
    headers.set("Accept", "application/json");
    config.headers = headers;

    const response = await fetch(this.getRequestUrl(url), config);

    if (response.status === DOWNTIME_STATUS_CODE && !SSR) {
      turnMaintenanceOn();
    }

    return response;
  }
}

export const APIV3 = new HTTPClientV3();
