import { Key, useSWRConfig } from "swr";
import { useContext } from "react";
import { pageMapping } from "../builder/mapping/page";
import { JSONAPIServerError } from "./JSONAPIServerError";
import { JSONAPIDocument, serializers } from "./Serializer";
import { ServerError } from "./ServerError";
import { EditorCtx, adminKey } from "../wysiwyg/EditorCtx";
import { useQueryParams } from "./QueryParams";
import { setAnalyticsDataField } from "./storages/analytics";
import {
  ACCESS_TOKEN_KEY,
  ACCESS_TOKEN_CLIENT,
  ACCESS_TOKEN_EXPIRY,
  ACCESS_TOKEN_UID,
  LOGGED_IN_COOKIE_NAME,
  UNAUTHORIZED_STATUS_CODE,
} from "./constants";
import { localStorageWrapper } from "./LocalStorage";

type RequestInitConfig = Omit<RequestInit, "method">;
export type HTTPResponse<T> = Omit<Response, "json"> & { json(): Promise<T> };

export const clearAuthenticationTokens = () => {
  if (localStorageWrapper.getItem(ACCESS_TOKEN_KEY)) {
    localStorageWrapper.removeItem(ACCESS_TOKEN_KEY);
    localStorageWrapper.removeItem(ACCESS_TOKEN_CLIENT);
    localStorageWrapper.removeItem(ACCESS_TOKEN_EXPIRY);
    localStorageWrapper.removeItem(ACCESS_TOKEN_UID);
    document.cookie = `${LOGGED_IN_COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    setAnalyticsDataField("analyticsUid", undefined);
  }
  // TODO: redirect to login when this method is called from http hadlers
};

export const useLogout = (isOpenMarketplace = false) => {
  const { isEditor, messages, setCurrentMessage } = useContext(EditorCtx);
  const {
    template_id: templateIdParam,
    [adminKey]: editorParam,
    "editor-preview": previewParam,
  } = useQueryParams();
  return () => {
    if (isEditor) {
      setCurrentMessage(messages.previewAlert);
      return;
    }
    clearAuthenticationTokens();
    if (!isOpenMarketplace) {
      window.location.href = pageMapping.Login.path;
    } else {
      // TODO: Review if we can clean the SWR's cache entirely.
      if (templateIdParam || editorParam || previewParam) {
        const url = new URL(window.location.href);
        url.searchParams.delete("template_id");
        url.searchParams.delete(adminKey);
        url.searchParams.delete("editor-preview");
        window.history.replaceState({}, document.title, url);
      }
      window.location.reload();
    }
  };
};

export abstract class HTTPClient {
  protected abstract host?: string;

  protected getRequestUrl(url: string, config?: { noSlash?: boolean }) {
    if (!this.host) {
      throw new Error("No host variable set. Check env configuration");
    }
    const fullUrl = `${this.host}${config?.noSlash ? "" : "/"}${url}`;
    const [protocol, path] = fullUrl.split("://");
    return `${protocol}://${path.replace(/[/]+/g, "/")}`;
  }

  protected abstract request<T>(
    url: string,
    config: RequestInit,
  ): Promise<HTTPResponse<T>>;

  public async get<T>(url: string) {
    return this.request<T>(url, {});
  }

  public async post<T>(url: string, config?: RequestInitConfig) {
    return this.request<T>(url, { ...config, method: "POST" });
  }

  public async put<T>(url: string, config?: RequestInitConfig) {
    return this.request<T>(url, { ...config, method: "PUT" });
  }

  public async patch<T>(url: string, config?: RequestInitConfig) {
    return this.request<T>(url, { ...config, method: "PATCH" });
  }

  public async delete<T>(url: string, config?: RequestInitConfig) {
    return this.request<T>(url, { ...config, method: "DELETE" });
  }
}

export function isResponseUnauthorized<T>(response: HTTPResponse<T>) {
  return response.status === UNAUTHORIZED_STATUS_CODE;
}

export async function fetcherWithHeaders<
  Doc extends JSONAPIDocument<T>,
  D extends (typeof serializers)[keyof typeof serializers],
  T = Doc extends JSONAPIDocument<infer K> ? K : never,
>(request: () => Promise<HTTPResponse<Doc>>, serializer: D) {
  const response = await request();
  try {
    if (isResponseUnauthorized(response)) {
      clearAuthenticationTokens();
    }
    const json = await response.json();
    if (json.errors) {
      throw json;
    }
    return {
      data: serializer.deserialize(json),
      meta: serializer.getMetaAdapter(json.meta),
      headers: response.headers,
    };
  } catch (e) {
    console.error(e);
    const { errors } = e as JSONAPIDocument<T>;
    if (Array.isArray(errors)) {
      throw new JSONAPIServerError(errors, response.url);
    }
    throw new ServerError(String(e), response.url, response.status);
  }
}

// TODO: move to HTTPClientV3
export async function defaultFetcher<
  Doc extends JSONAPIDocument<T>,
  D extends (typeof serializers)[keyof typeof serializers],
  T = Doc extends JSONAPIDocument<infer K> ? K : never,
>(request: () => Promise<HTTPResponse<Doc>>, serializer: D) {
  const { data, meta } = await fetcherWithHeaders<Doc, D, T>(
    request,
    serializer,
  );

  return {
    data,
    meta,
  };
}

interface ParallelFetcherRequest {
  request: () => Promise<HTTPResponse<any>>; // TODO: add the right type
  deserializer: any; // TODO: replace the "any"
}

export async function parallelFetcher(requests: ParallelFetcherRequest[]) {
  return Promise.all(
    requests.map(({ request, deserializer }) =>
      defaultFetcher(request, deserializer),
    ),
  );
}

// TODO: move to HTTPClientInternal
export async function internalFetcher<T>(
  request: () => Promise<HTTPResponse<T>>,
) {
  const response = await request();
  if (isResponseUnauthorized(response)) {
    clearAuthenticationTokens();
    throw new ServerError(
      String("Unauthorized request, user token is not valid."),
      response.url,
    );
  }
  try {
    return await response.json();
  } catch (e) {
    throw new ServerError(
      `URL ${response.url} : ${JSON.stringify(e)}`,
      response.url,
    );
  }
}

export const useRevalidateOnMount = (fallbackKey: Key) => {
  const { cache, revalidateOnMount } = useSWRConfig();
  return { revalidateOnMount: revalidateOnMount && !cache.get(fallbackKey) };
};
