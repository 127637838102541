import { styled, Typography } from "@mui/material";
import { useIntercom } from "react-use-intercom";
import { useEffect } from "react";
import {
  JWTErrorCause,
  sessionStorage,
} from "../../dataAccess/storages/session";
import { useNavigateSameTab } from "../../helpers/navigation";
import { pageMapping } from "../../builder/mapping/page";
import { useConfiguration } from "../../dataAccess/api/configuration";
import { ThemeButton } from "./ThemeComponents/ThemeButton";
import { TextButton } from "./TextButton";
import { useUser } from "../../dataAccess/api/user";

const Main = styled("div")(() => ({
  display: "flex",
  height: "100%",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "1rem",
}));

const ButtonsContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "1rem",
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: "1.5rem",
  letterSpacing: "0.2rem",
  fontSize: theme.typography.h2.fontSize,
  textAlign: "center",
  opacity: "0.3",
}));

const StyledText = styled(Typography)(() => ({
  maxWidth: "30rem",
  textAlign: "center",
  lineHeight: "1.3",
}));

const UnderlineButton = styled(TextButton)(() => ({
  textDecoration: "underline",
  marginTop: "0.5rem",
}));

const AuthenticationFailed = () => {
  const navigate = useNavigateSameTab();
  const { show, update } = useIntercom();
  const { configuration } = useConfiguration();
  const { jwtAuthenticationError } = useUser();

  useEffect(() => {
    update({
      hideDefaultLauncher: false,
    });
  }, []);

  async function onTryAgain(url: string) {
    if (configuration?.ssoSetting.forceJwtAuth) {
      navigate(configuration.ssoSetting.signInRedirectUrl);
    } else {
      await navigate(url);
      sessionStorage.setjwtAuthenticationError(null);
    }
  }

  const messages: Partial<Record<JWTErrorCause, string>> = {
    [JWTErrorCause.MISSMATCH_INVITATION_EMAIL]:
      "The account is not associated to the invitation, please try again with the invited email.",
  };

  return (
    <Main>
      <Title variant="h1">AUTHENTICATION FAILED</Title>
      <StyledText>
        {messages[jwtAuthenticationError?.cause || JWTErrorCause.UNKNOWN] ||
          "Please click to try again or contact us."}
      </StyledText>
      <ButtonsContainer>
        <ThemeButton
          onClick={() =>
            onTryAgain(jwtAuthenticationError?.link || pageMapping.Login.path)
          }
        >
          Try Again
        </ThemeButton>
        <UnderlineButton onClick={show}>Contact Us</UnderlineButton>
      </ButtonsContainer>
    </Main>
  );
};

export default AuthenticationFailed;
