import { styled } from "@mui/material";

export const CenteredContainer = styled("div")(() => ({
  width: "100vw",
  height: "100vh",
  maxHeight: 1000,
  minHeight: 300,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));
