import { useRouter } from "next/router";
import { useEffect, useState } from "react";

function sendMessage<T>(type: string, content: T | null = null) {
  window.parent.postMessage({ type, content }, "*");
}

const sizeUpdated = "size_updated";
const navigationEnd = "navigation_end";

export function NotifyChanges() {
  const [lastHeight, setLastHeight] = useState(0);
  const { events, asPath } = useRouter();

  useEffect(() => {
    const divToMonitor = document.getElementById("__next");

    if (!divToMonitor) {
      return;
    }

    const handleRouteChange = () => {
      sendMessage(navigationEnd, { path: asPath });
    };

    const fallback = setInterval(() => {
      const { scrollHeight } = divToMonitor;
      const childHeight = (
        Array.from(divToMonitor.childNodes) as HTMLElement[]
      ).reduce((total, current) => {
        const height = current?.offsetHeight || 0;
        return total + (height || 0);
      }, 0);
      const maxDiff = 300;
      const height =
        Math.abs(scrollHeight - childHeight) > maxDiff
          ? childHeight + maxDiff
          : scrollHeight;

      setLastHeight(height);
    }, 1000);

    events.on("routeChangeComplete", handleRouteChange);
    return () => {
      clearInterval(fallback);
      events.off("routeChangeComplete", handleRouteChange);
    };
  }, [asPath]);

  useEffect(() => {
    sendMessage(sizeUpdated, { height: lastHeight });
  }, [lastHeight]);

  return null;
}
