import { useMemo } from "react";
import { useConfiguration } from "../dataAccess/api/configuration";
import { removefileExtension } from "../helpers/formatter";
import { splitFontNameByComma } from "./themeOptionsBuilder";

export enum HeaderSizes {
  extraSmall = "h6",
  small = "h5",
  medium = "h4",
  large = "h3",
  extraLarge = "h2",
}

export enum BodySizes {
  extraSmall = "caption",
  small = "body2",
  medium = "body1",
  large = "h6",
  extraLarge = "h5",
}

function createFontFace(name: string, url: string) {
  return `
  @font-face {
    font-family: '${name}';
    src: local('${name}'), url('${url}');
  }
  `;
}

const defaultFonts = ["Sk-Modernist-Regular.ttf"] as const;

const defaultFontsData: Record<(typeof defaultFonts)[number], { url: string }> =
  {
    "Sk-Modernist-Regular.ttf": {
      url: `${process.env.NEXT_PUBLIC_CDN_IMAGES}/${process.env.NEXT_PUBLIC_FONT_URL_REGULAR}`,
    },
  };

function useFontsLayout() {
  const { configuration } = useConfiguration();
  const { brandingSetting } = configuration || {};
  const { fonts } = configuration?.publishedTemplate.properties.settings || {};
  const fontHeaders = splitFontNameByComma(
    fonts?.headers.fontFamily || brandingSetting?.headerFontFamily,
  );
  const fontBody = splitFontNameByComma(
    fonts?.body.fontFamily || brandingSetting?.bodyFontFamily,
  );

  const communityFonts = useMemo(
    () => configuration?.fonts.map((file) => file),
    [configuration?.fonts],
  );

  const fontSources = useMemo(() => {
    const communitySource = (communityFonts || [])?.filter(({ legend }) =>
      [fontHeaders, fontBody].includes(legend),
    );

    const defaultSource = defaultFonts?.filter(
      (fileName) =>
        [fontHeaders, fontBody].includes(removefileExtension(fileName)) ||
        !fontBody ||
        !fontHeaders,
    );

    const googleSource = [fontHeaders, fontBody].filter(
      (fontName) =>
        fontName &&
        ![
          ...communitySource.map(({ legend }) => legend),
          ...defaultSource.map((fontName) => removefileExtension(fontName)),
        ].includes(fontName || ""),
    );

    return { communitySource, defaultSource, googleSource };
  }, [fontHeaders, fontBody, communityFonts]);

  const loadedFonts = useMemo(
    () => (
      <style>
        {fontSources.defaultSource
          .map((fileName) =>
            createFontFace(
              removefileExtension(fileName),
              defaultFontsData[fileName]?.url,
            ),
          )
          .join("")}
        {fontSources.communitySource
          ?.map(({ legend, url }) => createFontFace(legend, url))
          .join("")}
      </style>
    ),
    [fontBody, fontHeaders],
  );

  return {
    googleFontsTag: fontSources.googleSource.length ? (
      <>
        <link
          rel="stylesheet"
          href={`https://fonts.googleapis.com/css2?${fontSources.googleSource
            .map((font) => `family=${font?.replace(/\s/g, "+")}`)
            .join("&")}&display=swap`}
        />
      </>
    ) : (
      <></>
    ),
    appFontsTag:
      fontSources.communitySource.length || fontSources.defaultSource.length ? (
        loadedFonts
      ) : (
        <></>
      ),
  };
}

export default useFontsLayout;
