import { FunctionComponent, useContext, useEffect } from "react";
import { useRouter } from "next/router";
import { EditorCtx } from "../wysiwyg/EditorCtx";
import AuthenticationFailed from "../components/ui/AuthenticationFailed";
import { isLocalStorageAvailable } from "../helpers/isLocalStorageAvailable";
import RequestAccess from "../components/ui/RequestAccess";
import { useNavigateSameTab } from "../helpers/navigation";
import { ServerError } from "../dataAccess/ServerError";
import {
  PageAccess,
  pageMapping,
  useCurrentRoute,
} from "../builder/mapping/page";
import { useConfiguration } from "../dataAccess/api/configuration";
import { User, useUser } from "../dataAccess/api/user";
import { SSR, iframe } from "../helpers/environment";
import { NotifyChanges } from "./NotifyChanges";
import { useQueryParams } from "../dataAccess/QueryParams";
import { updateAnalyticsUser } from "../dataAccess/api/analytics";
import {
  ACCESS_TOKEN_CLIENT,
  ACCESS_TOKEN_KEY,
  ACCESS_TOKEN_UID,
  LOGGED_IN_COOKIE_NAME,
  LOGGED_IN_COOKIE_VALUE,
  ACCESS_TOKEN_EXPIRY,
} from "../dataAccess/constants";
import { redirectIfSSOPopup } from "./ssoPopup";

export function setAuthorizationHeaders(headers: Headers) {
  const accessToken = headers.get(ACCESS_TOKEN_KEY) || "";
  const client = headers.get(ACCESS_TOKEN_CLIENT) || "";
  const expiry = headers.get(ACCESS_TOKEN_EXPIRY) || "";
  const uid = headers.get(ACCESS_TOKEN_UID) || "";
  if (window?.localStorage) {
    if (client) {
      localStorage.setItem(ACCESS_TOKEN_CLIENT, client);
    }
    if (expiry) {
      localStorage.setItem(ACCESS_TOKEN_EXPIRY, expiry);
    }
    if (uid) {
      localStorage.setItem(ACCESS_TOKEN_UID, uid);
    }
    if (accessToken) {
      localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    }
  }
}

function handleAuthenticatedUser(user?: User) {
  if (user) {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);
    document.cookie = `${LOGGED_IN_COOKIE_NAME}=${LOGGED_IN_COOKIE_VALUE}; expires=${expirationDate.toUTCString()}; path=/;`;
    if (!user.guestUser && user.organization) {
      updateAnalyticsUser().json();
    }
  }
}

interface Props {
  children: React.ReactNode;
}

export function canAccessPage(openSite?: boolean, pageAccess?: PageAccess) {
  return (
    pageAccess === PageAccess.Public ||
    (openSite && pageAccess === PageAccess.Restricted)
  );
}
// TODO: check case: have app in a tab, user login in a second tab, new token will be replace in localstorage and then used in all tabs,
// and if there is a form open in a previous open tab, may update incorrect information.

const Authorization: FunctionComponent<Props> = ({ children }: Props) => {
  const navigate = useNavigateSameTab();
  const route = useCurrentRoute();
  const { isEditor } = useContext(EditorCtx);
  const query = useQueryParams();
  const router = useRouter();
  const { configuration } = useConfiguration();
  const { user, loadingUser, jwtAuthenticationError } = useUser();
  const allowedPage = canAccessPage(
    configuration?.isOpenMarketplace,
    route?.currentPage.access,
  );

  useEffect(() => {
    handleAuthenticatedUser(user);
  }, [user]);

  const localStorageAvailable = isLocalStorageAvailable();

  const handleSSONavigation = (
    signInRedirectUrl: string,
    signUpRedirectUrl: string,
  ) => {
    const isCurrentPageSignUp =
      route?.currentPage.path === pageMapping.SignUp.path;
    if (configuration?.isOpenMarketplace) {
      if (isCurrentPageSignUp) {
        navigate(signUpRedirectUrl);
      } else if (route?.currentPage.isOnboardingPage) {
        navigate(signInRedirectUrl);
      }
    } else if (isCurrentPageSignUp) {
      navigate(signUpRedirectUrl);
    } else {
      navigate(signInRedirectUrl);
    }
  };

  useEffect(() => {
    if (!localStorageAvailable) {
      navigate({
        pathname: pageMapping.CookiesHelper.path,
      });
    }
  }, []);

  useEffect(() => {
    // If the user is still loading, exit
    if (loadingUser || !route) {
      return;
    }

    // If there is a token in the url, save the token in localstorage: TODO: use jwt instead
    const redirect = !iframe && configuration?.redirectUrl && !isEditor;
    if (!redirect && query["access-token"]) {
      const headers = new Headers();
      headers.set(ACCESS_TOKEN_KEY, query["access-token"]);
      headers.set(ACCESS_TOKEN_CLIENT, query.client || "");
      headers.set(ACCESS_TOKEN_UID, query.uid || "");
      setAuthorizationHeaders(headers);
      navigate(pageMapping.PLP.path);
      return;
    }

    const allowRedirect = !(
      iframe ||
      query?.screenshot ||
      query?.no_redirect ||
      isEditor
    );

    if (configuration?.redirectUrl && allowRedirect) {
      window.location.href = configuration?.redirectUrl;
      return;
    }

    // If the user is not logged in and SSO is enabled, redirect to the appropriate page
    if (!user && configuration?.ssoSetting.forceJwtAuth) {
      const { signInRedirectUrl, signUpRedirectUrl } = configuration.ssoSetting;
      handleSSONavigation(
        signInRedirectUrl || pageMapping.Login.path,
        signUpRedirectUrl || pageMapping.SignUp.path,
      );
      return;
    }

    // If the user is not logged in and the current page is not allowed, redirect to the login page
    if (!user && !allowedPage) {
      navigate({
        pathname: pageMapping.Login.path,
        query: {
          retUrl: router?.asPath,
        },
      });
      return;
    }

    // If the user is not confirmed and the marketplace is close, redirect to verify email page
    if (user && !user.confirmed && !configuration?.isOpenMarketplace) {
      navigate({
        pathname: pageMapping.VerifyEmail.path,
        query: {
          email: user.email ?? "",
        },
      });
      return;
    }

    // If the user is on an onboarding page, redirect to the PLP
    if (
      user &&
      route?.currentPage.isOnboardingPage &&
      !route?.currentPage.ignoreRedirectAfterAuthentication &&
      !isEditor
    ) {
      navigate({
        pathname: pageMapping.PLP.path,
      });
    }
  }, [user, loadingUser, route !== undefined]);

  if (configuration instanceof ServerError) {
    // Error requesting configuration in SSR, so it will never reach here as application will show an error earlier.
    return <div>Error loading configuration. Please refresh the page.</div>;
  }

  if (loadingUser) {
    return <></>;
  }

  if (jwtAuthenticationError) {
    return (
      <>
        {/* TODO: do not render anything in this component and create pages for AuthenticationFailed & RequestAccess errors */}
        {iframe && !SSR && <NotifyChanges />}
        <AuthenticationFailed />;
      </>
    );
  }

  if (redirectIfSSOPopup()) {
    return <></>;
  }

  if (allowedPage || (user && user?.organization?.allowedInMarketplace)) {
    return <>{children}</>;
  }

  if (
    user?.organization?.allowedInMarketplace === false &&
    !configuration?.isOpenMarketplace
  ) {
    return (
      <>
        {iframe && !SSR && <NotifyChanges />}
        <RequestAccess />
      </>
    );
  }
  return <></>;
};

export default Authorization;
