import { ErrorObject } from "json-api-serializer";
import { ServerError } from "./ServerError";

export class JSONAPIServerError extends ServerError {
  private info: ErrorObject[];

  constructor(info: ErrorObject[], url: string) {
    const [error] = info;
    super(error?.detail || "Unkown Error", url, error.code);
    this.info = info;
    console.error("Error", info);
    console.trace();
  }
}
