import { useEffect } from "react";
import {
  ACCESS_TOKEN_CLIENT,
  ACCESS_TOKEN_KEY,
  ACCESS_TOKEN_UID,
  LOGIN_SUCCESS_POPUP_MESSAGE,
  SSO_POPUP_NAME,
} from "../dataAccess/constants";
import { setAuthorizationHeaders } from "./Authorization";
import { SSR } from "../helpers/environment";
import { getNonceKey } from "../dataAccess/SSO";
import { localStorageWrapper } from "../dataAccess/LocalStorage";

export function useSSOPopupListener() {
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (
        event.origin.endsWith(".builtfirst.com") &&
        event.data === LOGIN_SUCCESS_POPUP_MESSAGE
      ) {
        const { localStorage: sourceLocalStorage } = event.source as Window;
        const headers = new Headers();
        headers.set(
          ACCESS_TOKEN_KEY,
          sourceLocalStorage.getItem(ACCESS_TOKEN_KEY) || "",
        );
        headers.set(
          ACCESS_TOKEN_CLIENT,
          sourceLocalStorage.getItem(ACCESS_TOKEN_CLIENT) || "",
        );
        headers.set(
          ACCESS_TOKEN_UID,
          sourceLocalStorage.getItem(ACCESS_TOKEN_UID) || "",
        );
        setAuthorizationHeaders(headers);
        window.location.reload();
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
}

export function redirectIfSSOPopup() {
  if (!SSR && window.name === SSO_POPUP_NAME) {
    window.opener?.postMessage(LOGIN_SUCCESS_POPUP_MESSAGE, "*");
    window.close();
    return true;
  }
}

export function openSSOPopup(url: string, provider: string) {
  const width = 600;
  const height = 650;
  const left = (window.screen.width - width) / 2;
  const top = (window.screen.height - height) / 2;
  const popup = window.open(
    url,
    SSO_POPUP_NAME,
    `width=${width},height=${height},left=${left},top=${top}`,
  );
  const nonceKey = getNonceKey(provider);
  popup?.localStorage.setItem(
    nonceKey,
    localStorageWrapper.getItem(nonceKey) || "",
  );
}
