import Head from "next/head";
import { styled, Typography } from "@mui/material";
import { useRouter } from "next/router";
import NotFoundCode from "../../assets/svg/404.svg";
import { ThemeButton } from "./ThemeComponents/ThemeButton";
import { BackButton } from "./BackButton";

const Container = styled("div")(() => ({
  height: "100%",
}));

const Main = styled("div")(() => ({
  display: "flex",
  height: "100%",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const PageNotFound = styled(Typography)(() => ({
  marginBottom: "2rem",
  marginTop: "2rem",
  color: "black",
}));

const BackButtonContainer = styled("div")(() => ({
  position: "absolute",
  top: "3rem",
  left: "3rem",
}));

const NotFound = () => {
  const router = useRouter();
  return (
    <Container>
      <Head>
        <title>Page not found</title>
        <meta name="robots" content="noindex, follow" />
      </Head>
      <Main>
        <BackButtonContainer>
          <BackButton onClick={() => router.back()} />
        </BackButtonContainer>
        <NotFoundCode alt="404 icon" />
        <PageNotFound variant="h1">Page not found</PageNotFound>
        <ThemeButton href="/">Go Back to the Marketplace</ThemeButton>
      </Main>
    </Container>
  );
};

export default NotFound;
