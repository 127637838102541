import type { AnalyticEvent } from "../../app/AnalyticsTracker";
import { defaultFetcher, fetcherWithHeaders } from "../HTTPClient";
import { APIV3 } from "../HTTPClientV3";
import { JSONAPIDocument, serializers } from "../Serializer";
import { getAnalyticsData, setAnalyticsDataField } from "../storages/analytics";
import { ANALYTICS_UID } from "../constants";

export interface Referral {
  discountId: string;
}

const base = "analytics";

export function postReferral(itemSlug: string, dealId?: string) {
  const url = `/items/${itemSlug}/referrals`;
  const request = () =>
    APIV3.post<JSONAPIDocument<null>>(url, {
      body: JSON.stringify(
        serializers.analytics_event.serialize({
          discountId: dealId,
        }),
      ),
      keepalive: true,
    });
  return {
    json: () => defaultFetcher(request, serializers.analytics_event),
  };
}

export function trackEvent(event: AnalyticEvent) {
  const request = () => {
    const { analyticsUid } = getAnalyticsData();
    return APIV3.post<JSONAPIDocument<null>>(base, {
      body: JSON.stringify(
        serializers.analytics_event.serialize({
          analyticsEvent: event.type,
          info: event.info,
        }),
      ),
      headers: analyticsUid
        ? {
            [ANALYTICS_UID]: analyticsUid,
          }
        : {},
      keepalive: true,
    });
  };
  return {
    json: async () => {
      const response = await fetcherWithHeaders(
        request,
        serializers.analytics_event,
      );
      if (!getAnalyticsData().analyticsUid) {
        setAnalyticsDataField(
          "analyticsUid",
          response.headers.get(ANALYTICS_UID) || undefined,
        );
      }
      return response;
    },
  };
}

export function updateAnalyticsUser() {
  const { analyticsUid } = getAnalyticsData();
  const request = () =>
    APIV3.put<JSONAPIDocument<null>>(base, {
      headers: analyticsUid
        ? {
            [ANALYTICS_UID]: analyticsUid,
          }
        : {},
    });
  return {
    json: () => {
      if (analyticsUid) {
        return defaultFetcher(request, serializers.analytics_event);
      }
    },
  };
}
