import { PropsWithChildren } from "react";
import { useIntercom } from "react-use-intercom";
import { useTheme } from "@mui/material";
import { SSR } from "../helpers/environment";

export function IntercomInnerWrapper({ children }: PropsWithChildren) {
  const { update } = useIntercom();
  const { settings } = useTheme();
  const { hideSupportChat } = settings || {};
  if (!SSR) {
    update({
      hideDefaultLauncher: hideSupportChat,
    });
  }
  return <>{children}</>;
}
