export const formatToCurrency = (data: number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  return formatter.format(data);
};

type StringCaseType =
  | "camelCase"
  | "snake_case"
  | "kebab-case"
  | "case with spaces";
export const toXCase = (str: string, type: StringCaseType) => {
  // a mix of following implementations:
  // https://github.com/30-seconds/30-seconds-of-code/blob/master/snippets/toKebabCase.md
  // https://github.com/30-seconds/30-seconds-of-code/blob/master/snippets/toSnakeCase.md
  // https://github.com/30-seconds/30-seconds-of-code/blob/master/snippets/toCamelCase.md
  const match =
    str &&
    str.match(
      /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g,
    );

  if (!match) {
    return str;
  }

  if (type === "camelCase") {
    const s = match
      .map((x) => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase())
      .join("");
    return s.slice(0, 1).toLowerCase() + s.slice(1);
  }

  const separators: Record<Exclude<StringCaseType, "camelCase">, string> = {
    snake_case: "_",
    "kebab-case": "-",
    "case with spaces": " ",
  };
  return match.map((x) => x.toLowerCase()).join(separators[type]);
};

export const getNameInitials = (
  firstName?: string,
  lastName?: string,
): string => {
  const firstInitial = firstName?.charAt(0)?.toUpperCase() ?? "";
  const lastInitial = lastName?.charAt(0)?.toUpperCase() ?? "";
  return `${firstInitial}${lastInitial}`;
};
export function splitFullName(fullName: string): {
  firstName: string;
  lastName: string;
} {
  const names = fullName.trim().split(" ");
  const lastName = names.pop() || "";
  const firstName = names.join(" ");
  return { firstName, lastName };
}

export function removefileExtension(fileName: string) {
  return fileName.replace(/\.[^/.]+$/, "");
}

export function getRandomKey(options?: { numberOfBites: number }) {
  return window.crypto
    .getRandomValues(new Uint8Array(options?.numberOfBites || 16))
    .join("");
}

export function simpleHash(str: string) {
  let hash = 5381;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash * 33) ^ char;
  }

  // eslint-disable-next-line no-bitwise
  return String(hash >>> 0);
}

export function jsonStringifySafe<T>(json: T) {
  try {
    return JSON.stringify(json);
  } catch (e) {
    console.error(e);
  }
  return null;
}

export function jsonParseSafe<T>(json: string): T | null {
  try {
    return JSON.parse(json);
  } catch (e) {
    console.error(e);
  }
  return null;
}
export const getSubdomainFromUrl = () => {
  const fullUrl = window.location.href;
  const subdomain = fullUrl.split(".")[0];
  const parsedSubdomain = subdomain.substring(subdomain.lastIndexOf("/") + 1);

  return parsedSubdomain;
};

export function replaceBracesKey(
  str: string,
  { key, value }: { key: string; value?: string },
) {
  return str?.replace(new RegExp(`{{${key}}}`, "g"), value || "");
}
