import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import PrevOutlinedIcon from "../assets/svg/prev-outlined.svg";
import NextOutlinedIcon from "../assets/svg/next-outlined.svg";

import { IconSetType } from "../types/theme";

type IconType = typeof ArrowBackIcon;

export interface IconSet {
  arrowBack: IconType;
  arrowDown: IconType;
  navigateNext: IconType;
  navigateBefore: IconType;
}

const filledIcons: IconSet = {
  arrowBack: ArrowBackIcon,
  navigateBefore: NavigateBeforeIcon,
  navigateNext: NavigateNextIcon,
  arrowDown: ArrowDownIcon,
};

export const getIconsBaseClass = (variant?: IconSetType) => {
  switch (variant) {
    case "outlined":
      return "material-icons-outlined";
    case "rounded":
      return "material-icons-round";
    case "sharp":
      return "material-icons-sharp";
    case "twoTone":
      return "material-icons-two-tone";
    default:
      return "material-icons";
  }
};

export const getIconsUrl = (variant: IconSetType) => {
  switch (variant) {
    case "outlined":
      return "https://fonts.googleapis.com/icon?family=Material+Icons+Outlined";
    case "rounded":
      return "https://fonts.googleapis.com/icon?family=Material+Icons+Round";
    case "sharp":
      return "https://fonts.googleapis.com/icon?family=Material+Icons+Sharp";
    case "twoTone":
      return "https://fonts.googleapis.com/icon?family=Material+Icons+Two+Tone";
    default:
      return "https://fonts.googleapis.com/icon?family=Material+Icons";
  }
};

export const iconSets: Record<IconSetType, IconSet> = {
  outlined: {
    arrowBack: PrevOutlinedIcon,
    navigateBefore: PrevOutlinedIcon,
    navigateNext: NextOutlinedIcon,
    arrowDown: ArrowDownOutlinedIcon,
  },
  // We must review this because the icons are custom.
  filled: filledIcons,
  rounded: filledIcons,
  sharp: filledIcons,
  twoTone: filledIcons,
};
