import { styled, Typography } from "@mui/material";
import { useIntercom } from "react-use-intercom";
import { useEffect } from "react";
import { useConfiguration } from "../../dataAccess/api/configuration";
import { useLogout } from "../../dataAccess/HTTPClient";
import { ThemeButton } from "./ThemeComponents/ThemeButton";
import { TextButton } from "./TextButton";

const Main = styled("div")(() => ({
  display: "flex",
  height: "100%",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "1rem",
}));

const ButtonsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "1rem",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  marginBottom: "2rem",
  letterSpacing: "0.2rem",
  fontSize: theme.typography.h2.fontSize,
  textAlign: "center",
  opacity: "0.3",
}));

export const StyledText = styled(Typography)(() => ({
  maxWidth: "30rem",
  textAlign: "center",
}));

const StyledButton = styled(ThemeButton)(() => ({
  marginTop: "1rem",
}));

const UnderlineButton = styled(TextButton)(() => ({
  textDecoration: "underline",
}));

const RequestAccess = () => {
  const { show, update } = useIntercom();
  const { configuration } = useConfiguration();
  const logout = useLogout(configuration?.isOpenMarketplace);

  useEffect(() => {
    update({
      hideDefaultLauncher: false,
    });
  }, []);

  return (
    <Main>
      <Title variant="h1">YOUR ACCOUNT IS UNDER REVIEW</Title>
      <StyledText>
        A request has been sent to the owner of this marketplace, and access
        will be granted to you once approved.
      </StyledText>
      {configuration?.website && (
        <StyledButton href={configuration.website}>
          GO BACK TO {configuration.website.replace(/(^\w+:|^)\/\//, "")}
        </StyledButton>
      )}
      <ButtonsContainer>
        <UnderlineButton onClick={show}>CONTACT US</UnderlineButton>
        <UnderlineButton onClick={() => logout()}>SIGN OUT</UnderlineButton>
      </ButtonsContainer>
    </Main>
  );
};

export default RequestAccess;
