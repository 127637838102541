import { useTheme } from "@mui/material";
import { useContext, useMemo } from "react";
import { grey } from "@mui/material/colors";
import { EditorCtx } from "../wysiwyg/EditorCtx";

export const ThemeGlobalStyles = () => {
  const theme = useTheme();
  const { liveConfig } = useContext(EditorCtx);
  const style = useMemo(
    () => (
      <style>
        {(() => `
          html, body, #root, #__next {
            font-family: ${theme.settings.fonts.body.fontFamily};
            scrollbar-width: ${theme.settings.hideScrollBar ? "none" : "thin"};
            scrollbar-color: ${grey[300]};
            background-color: ${theme.palette.background.default};
            overflow-x: hidden;
          }

          ::-webkit-scrollbar {
            width: ${theme.settings.hideScrollBar ? "0rem" : ".5rem"};
          }
          
          .MuiChip-label {
            font-size: 0.9rem;
          }
        `)()}
      </style>
    ),
    [theme, liveConfig],
  );
  return style;
};
