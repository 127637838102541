import { FunctionComponent, createContext, useContext } from "react";
import type { Property } from "csstype";
import { alpha, createTheme, useTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import { GridSettings, Section } from "../components/public/Grid";
import { createThemeOptions } from "./themeOptionsBuilder";
import { PageMapping, useCurrentRoute } from "./mapping/page";
import type { ComponentMapping } from "./mapping/component";
import { Capitalization, Shadows } from "../types/theme";
import {
  Configuration,
  useConfiguration,
} from "../dataAccess/api/configuration";
import { ServerError } from "../dataAccess/ServerError";
import { EditorCtx } from "../wysiwyg/EditorCtx";

type SettingsSchema = { [setting: string]: { type: string; default: string } };

// TODO: Remove this any and add the correct settings for each component/page
export type SettingsConfiguration = Record<string, any>;

interface AvailableComponent {
  type: keyof ComponentMapping;
}

export interface Theme {
  id: string;
  name: string;
  components: {
    id: string;
    type: keyof ComponentMapping;
    name: string;
    settings: SettingsSchema;
    availableComponents: AvailableComponent[];
  }[];
  pages: {
    id: keyof PageMapping;
    name?: string;
    availableComponents?: {
      id: string;
      name: string;
      sections: [
        {
          id: string;
          name: string;
          availableComponents: AvailableComponent[];
          settings: SettingsSchema;
        },
      ];
    };
  }[];
  templates: {
    id: string;
    name: string;
    configuration: never;
  }[];
  settings: SettingsSchema;
}

export interface ThemeComponent {
  id: string;
  Element: FunctionComponent;
  settings: {
    width: string;
    height: string;
  };
}

export function useCapitalization(
  themeCapitalization?: Capitalization["capitalization"],
) {
  const capitalization: Record<
    Capitalization["capitalization"],
    Property.TextTransform
  > = {
    AG: "uppercase",
    Ag: "capitalize",
    ag: "lowercase",
  };
  return themeCapitalization && capitalization[themeCapitalization];
}

export function useShadows(themeShadows: Shadows["shadows"]) {
  const theme = useTheme();
  return `${theme.typography.pxToRem(
    themeShadows.horizontalOffset,
  )} ${theme.typography.pxToRem(
    themeShadows.verticalOffset,
  )} ${theme.typography.pxToRem(themeShadows.blur)} ${alpha(
    theme.palette.common.black,
    themeShadows.opacity,
  )}`;
}
interface Props {
  children: React.ReactNode;
}
function isArray(value: any): value is unknown[] {
  return Array.isArray(value);
}

export function getComponentSettings<T>(
  configuration: Configuration,
  pageId: keyof PageMapping,
  componentType: keyof ComponentMapping,
): T {
  return configuration.publishedTemplate?.properties.pages
    .find((page) => page.id === pageId)
    ?.components?.find((component) => component.type === componentType)
    ?.settings as T;
}
export function getGridHeaders(configuration: Configuration): Section[] {
  const gridSettings = getComponentSettings<GridSettings>(
    configuration,
    "PLP",
    "Grid",
  );

  return isArray(gridSettings?.showSectionsBy)
    ? gridSettings?.showSectionsBy
    : [];
}
// TODO: Add all the setting following the same structure than in the config
interface PagesSettings {
  gridHeaders: Section[];
}

const PagesSettingsCtx = createContext<PagesSettings>({
  gridHeaders: [],
});

export function usePagesSettings() {
  return useContext(PagesSettingsCtx);
}

export function ThemeLoader({ children }: Props) {
  const { configuration } = useConfiguration();
  const currentPage = useCurrentRoute();
  const { isEditor } = useContext(EditorCtx);
  const pageConfiguration =
    configuration?.publishedTemplate.properties.pages.find(
      ({ id }: { id: string }) => id === currentPage?.id,
    );
  if (configuration && !(configuration instanceof ServerError)) {
    const theme = createTheme(
      createThemeOptions(configuration, pageConfiguration, isEditor),
    );
    const settings = {
      gridHeaders: getGridHeaders(configuration),
    };
    return (
      <PagesSettingsCtx.Provider value={settings}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </PagesSettingsCtx.Provider>
    );
  }
  return <>Error: No theme information, please contact support</>;
}
