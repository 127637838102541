import {
  AlertProps,
  Button,
  Dialog,
  DialogProps,
  Grow,
  Typography,
  styled,
} from "@mui/material";
import { FunctionComponent, ReactNode, useState } from "react";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";

interface Props extends Omit<DialogProps, "children" | "title" | "open"> {
  severity?: AlertProps["severity"];
  title?: ReactNode;
  description?: ReactNode;
  renderActions?: () => ReactNode;
}

const Container = styled("div")(({ theme }) => ({
  padding: theme.spacing(6),
  gap: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: "100%",
  width: 580,
}));

const ActionContainer = styled("div")(({ theme }) => ({
  gap: theme.spacing(2),
  display: "flex",
}));

const AlertDialog: FunctionComponent<Props> = ({
  title,
  description,
  severity,
  renderActions,
  onClose,
  ...props
}) => {
  const [open, setOpen] = useState(true);
  const onCloseHandler = () => {
    setOpen(false);
    if (onClose) {
      onClose({}, "backdropClick");
    }
  };
  const fontSize = 46;
  const severityIcons = {
    error: <ErrorRoundedIcon color={severity} sx={{ fontSize }} />,
    warning: <ReportProblemRoundedIcon color={severity} sx={{ fontSize }} />,
    success: <CheckCircleRoundedIcon color={severity} sx={{ fontSize }} />,
    info: <InfoRoundedIcon color={severity} sx={{ fontSize }} />,
  };

  const currentSeverityIcon = severity ? severityIcons[severity] : <></>;
  return (
    <Dialog
      {...props}
      open={open}
      onClose={onCloseHandler}
      TransitionComponent={Grow}
      maxWidth="lg"
    >
      <Container>
        {currentSeverityIcon}
        {title && (
          <Typography color="primary" variant="h5" textAlign="center">
            {title}
          </Typography>
        )}
        {description && (
          <Typography textAlign="center">{description}</Typography>
        )}
        <ActionContainer>
          {renderActions ? (
            renderActions()
          ) : (
            <Button variant="contained" onClick={onCloseHandler}>
              Accept
            </Button>
          )}
        </ActionContainer>
      </Container>
    </Dialog>
  );
};

export default AlertDialog;
