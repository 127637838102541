import { User } from "../dataAccess/api/user";

const noUserMock: User | undefined = undefined;

export const mocks = {
  noUserMock,
};

export type Mock = typeof mocks;
export type MockKey = keyof Mock;
