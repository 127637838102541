import { Button, darken, Divider, styled, Typography } from "@mui/material";
import StoreOutlinedIcon from "@mui/icons-material/LocalGroceryStoreOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { ReactNode } from "react";
import { CenteredContainer } from "./CenteredPageContainer";
import { primary } from "../../wysiwyg/colors";

const Main = styled("div")(() => ({
  maxWidth: 400,
  display: "flex",
  flexDirection: "column",
  gap: 28,
  alignItems: "center",
}));

const StyledButton = styled(Button)(() => ({
  background: primary,
  borderRadius: 12,
  marginTop: 8,
  "&:hover": {
    background: darken(primary, 0.2),
  },
}));

const StyledText = styled(Typography)(() => ({
  maxWidth: "30rem",
  textAlign: "center",
  lineHeight: "1.4",
  fontSize: 24,
  opacity: "0.4",
}));

const StyledDivider = styled(Divider)(() => ({
  width: "115%",
  maxWidth: "80vw",
  borderColor: "#aaa",
}));

const ClosedIcon = styled("div")(() => ({
  position: "relative",
}));

const StyledCancelOutlinedIcon = styled(CancelOutlinedIcon)(() => ({
  position: "absolute",
  top: -15,
  right: -15,
  fontSize: 30,
}));

const StyledStoreOutlinedIcon = styled(StoreOutlinedIcon)(() => ({
  fontSize: 80,
}));

type MessageType = "buyerNotAvailable" | "templateNotAvailable" | "noSession";

const messages: Record<MessageType, ReactNode> = {
  buyerNotAvailable: (
    <>
      <div>
        <StyledText>
          This Marketplace is disabled at this moment, please try again later!
        </StyledText>
      </div>
      <StyledDivider />
      <div>
        <StyledText>
          Or if you are building the marketplace, click below to Sign In.
        </StyledText>
      </div>
    </>
  ),
  templateNotAvailable: (
    <>
      <div>
        <StyledText>There is no selected template to edit.</StyledText>
      </div>
      <StyledDivider />
      <div>
        <StyledText>
          Please click below to Sign In and select a template from your
          Marketplace Templates.
        </StyledText>
      </div>
    </>
  ),
  noSession: (
    <>
      <div>
        <StyledText>Session expired.</StyledText>
      </div>
      <StyledDivider />
      <div>
        <StyledText>
          Please click below to Sign In and select a template from your
          Marketplace Templates.
        </StyledText>
      </div>
    </>
  ),
};

interface Props {
  message?: MessageType;
}

const SiteUnavailable = ({ message = "buyerNotAvailable" }: Props) => (
  <CenteredContainer>
    <style>
      {(() => `
      * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
      }
    `)()}
    </style>
    <Main>
      <ClosedIcon>
        <StyledStoreOutlinedIcon />
        <StyledCancelOutlinedIcon />
      </ClosedIcon>
      {messages[message]}
      <StyledButton
        href={process.env.NEXT_PUBLIC_MANAGER_APP_URL}
        variant="contained"
      >
        Sign In
      </StyledButton>
    </Main>
  </CenteredContainer>
);

export default SiteUnavailable;
