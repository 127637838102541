import {
  createContext,
  Dispatch,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { deepmerge } from "@mui/utils";
import { useRouter } from "next/router";
import { deepEqual } from "../helpers/object";
import { QueryParams, useQueryParams } from "../dataAccess/QueryParams";
import { getRandomKey } from "../helpers/formatter";
import {
  ThemeComponent,
  ThemeConfiguration,
  JsonThemeObject,
} from "./JsonThemeObject";
import { themeDefault } from "./schemas/configuration";
import { Mock, MockKey, mocks as allMocks } from "./mocks";
import type { Device } from "./EditorPage";
import { SSR } from "../helpers/environment";
import type { Template } from "../dataAccess/api/template";
import { MessageList, useEditorMessages } from "./useEditorMessages";
import { managerAppMarketplacePagePath } from "../helpers/navigation";

export const adminKey = "editor_feature-internal";
export const currentComponentWrapperClassName = "current-component-wrapper";
export const dataVirtualWidthAttribute = "data-virtualwidth";
export const editorAccessedOnceAtLeastKey = "editor-accessed-once-at-least";
export const currentPreviewKey = "editor-preview";
export const storedTemplatesKey = "editor-templates";
export function buildManageMarketplacePath(
  marketplaceId?: string,
  path?: string,
) {
  return !marketplaceId
    ? ""
    : `${managerAppMarketplacePagePath}/${marketplaceId}${
        path && !path?.startsWith("/") ? `/${path}` : path
      }`;
}

export interface CanvasValues {
  width: number;
  height: string;
  scale: number;
  zoomRatio: number;
  canZoom: boolean;
}

interface Ctx {
  jsonTheme?: JsonThemeObject;
  liveConfig?: ThemeConfiguration;
  setLiveConfig: Dispatch<SetStateAction<ThemeConfiguration | undefined>>;
  isEditor?: boolean;
  template?: Template;
  uniqueIdWasAssigned?: boolean;
  mocks: Partial<Mock>;
  messages: Partial<MessageList>;
  addMock: (mockKeys: MockKey) => void;
  removeMock: (mockKeys: MockKey) => void;
  canvasValues: CanvasValues;
  setCanvasValues: Dispatch<SetStateAction<CanvasValues>>;
  selectorEnabled: boolean;
  setSelectorEnabled: Dispatch<SetStateAction<boolean>>;
  lastSavedConfig?: ThemeConfiguration;
  setLastSavedConfig: Dispatch<SetStateAction<ThemeConfiguration | undefined>>;
  unsavedChanges?: boolean;
  lastPublishedConfig?: ThemeConfiguration;
  setLastPublishedConfig: Dispatch<
    SetStateAction<ThemeConfiguration | undefined>
  >;
  unpublishedChanges?: boolean;
  fitContentEnabled: boolean;
  setFitContentEnabled: Dispatch<SetStateAction<boolean>>;
  currentMessage?: ReactNode;
  setCurrentMessage: Dispatch<SetStateAction<ReactNode | undefined>>;
  currentDevice: Device;
  setCurrentDevice: Dispatch<SetStateAction<Device>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const initialState: Ctx = {
  jsonTheme: undefined,
  liveConfig: undefined,
  isEditor: false,
  mocks: {},
  messages: {},
  uniqueIdWasAssigned: false,
  currentMessage: undefined,
  setCurrentMessage: () => {},
  setLiveConfig: () => {},
  addMock: () => {},
  removeMock: () => {},
  loading: false,
  setLoading: () => {},
  selectorEnabled: false,
  setSelectorEnabled: () => {},
  fitContentEnabled: true,
  setFitContentEnabled: () => {},
  lastSavedConfig: undefined,
  setLastSavedConfig: () => {},
  lastPublishedConfig: undefined,
  setLastPublishedConfig: () => {},
  currentDevice: "desktop",
  setCurrentDevice: () => {},
  setCanvasValues: () => {},
  canvasValues: {
    height: "0",
    width: 0,
    zoomRatio: 1,
    canZoom: false,
    scale: 1,
  },
};

export const EditorCtx = createContext<Ctx>(initialState);

export function isInEditor(admin?: string | null) {
  return !SSR && admin !== undefined && admin !== null;
}

export function getRandomId(type: string) {
  return `${type}.${getRandomKey({ numberOfBites: 4 })}`;
}

function fallbackUniqueids(jsonTheme: ThemeConfiguration) {
  let uniqueIdWasAssigned = false;
  const jsonWithUniqueIds = { ...jsonTheme };
  function assignUniqueIds(components: ThemeComponent[]) {
    components.forEach((component) => {
      if (!component.uniqueId) {
        uniqueIdWasAssigned = true;
        component.uniqueId = getRandomId(component.type);
      }
      if (component.components) {
        assignUniqueIds(component.components);
      }
    });
  }

  jsonWithUniqueIds.uniqueId = "theme";

  jsonWithUniqueIds.pages.forEach((page) => {
    if (!page.uniqueId) {
      uniqueIdWasAssigned = true;
      page.uniqueId = getRandomId(page.id);
    }
    if (page.components) {
      assignUniqueIds(page.components);
    }
  });

  return { jsonWithUniqueIds, uniqueIdWasAssigned };
}

export interface Props {
  template: Template;
  isEditor: boolean;
  marketplaceId?: string;
}

let uniqueIdWasAssigned = false;

function getEditorValue(param: keyof QueryParams = adminKey) {
  return new URLSearchParams(window.location.search).get(param) || "";
}

function searchIncludesEditorParam(param: keyof QueryParams = adminKey) {
  return new URLSearchParams(window.location.search).get(param) !== null;
}

export function useEditor() {
  const router = useRouter();
  const {
    [adminKey]: admin,
    template_id,
    "editor-preview": preview,
    manage_ret_url,
  } = useQueryParams();
  const [editorValue, setEditorValue] = useState<string | undefined>(admin);
  const [editorPreviewValue, setEditorPreviewValue] = useState<
    string | undefined
  >(preview);
  const [templateValue, setTemplateValue] = useState<string | undefined>(
    template_id,
  );
  const [manageRetUrlValue, setManageRetUrlValue] = useState<
    string | undefined
  >(manage_ret_url);
  const inEditor = !SSR && isInEditor(editorValue);

  if (!SSR && !inEditor) {
    window.onbeforeunload = null;
  }

  useEffect(() => {
    setEditorValue(searchIncludesEditorParam() ? getEditorValue() : undefined);
  }, [setEditorValue, admin]);

  useEffect(() => {
    let editorValueBeforeNavigate: string;
    let templateValueBeforeNavigate: string;
    let manageRetUrlValueBeforeNavigate: string;
    let editorPreviewValueBeforeNavigate: string | null;
    const onBeforeHistoryChange = () => {
      editorValueBeforeNavigate = getEditorValue();
      templateValueBeforeNavigate = getEditorValue("template_id");
      manageRetUrlValueBeforeNavigate = getEditorValue("manage_ret_url");
      editorPreviewValueBeforeNavigate = new URLSearchParams(
        window.location.search,
      ).get("editor-preview");
    };
    const onRouteChangeComplete = () => {
      const newUrl = new URL(window.location.href);
      if (inEditor) {
        if (!searchIncludesEditorParam()) {
          newUrl.searchParams.set(adminKey, editorValueBeforeNavigate);
        }
        window.history.pushState({}, "", newUrl);
        setEditorValue(getEditorValue());
      }
      if (
        !searchIncludesEditorParam("editor-preview") &&
        typeof editorPreviewValueBeforeNavigate === "string"
      ) {
        newUrl.searchParams.set(
          "editor-preview",
          editorPreviewValueBeforeNavigate || "",
        );
        window.history.pushState({}, "", newUrl);
        setEditorPreviewValue(getEditorValue("editor-preview"));
      }
      if (
        !searchIncludesEditorParam("template_id") &&
        templateValueBeforeNavigate
      ) {
        newUrl.searchParams.set("template_id", templateValueBeforeNavigate);
        window.history.pushState({}, "", newUrl);
        setTemplateValue(getEditorValue("template_id"));
      }
      if (
        !searchIncludesEditorParam("manage_ret_url") &&
        manageRetUrlValueBeforeNavigate
      ) {
        newUrl.searchParams.set(
          "manage_ret_url",
          manageRetUrlValueBeforeNavigate,
        );
        window.history.pushState({}, "", newUrl);
        setManageRetUrlValue(getEditorValue("manage_ret_url"));
      }
    };
    router.events.on("beforeHistoryChange", onBeforeHistoryChange);
    router.events.on("routeChangeComplete", onRouteChangeComplete);
    return () => {
      router.events.off("beforeHistoryChange", onBeforeHistoryChange);
      router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
  }, []);

  return {
    editorParamExist: inEditor,
    editorValue,
    manageRetUrl: manageRetUrlValue,
    templateId: templateValue,
    inPreview: editorPreviewValue !== undefined,
  };
}

export function EditorProvider({
  children,
  template,
  isEditor,
  marketplaceId,
}: PropsWithChildren<Props>) {
  const { "editor-device": paramDevice } = useQueryParams();
  const {
    jsonWithUniqueIds: configurationWithUniqueIds,
    uniqueIdWasAssigned: uniqueIdWasAssignedOnce,
  } = useMemo(
    () => fallbackUniqueids(template.properties),
    [template.properties],
  );

  if (uniqueIdWasAssignedOnce) {
    uniqueIdWasAssigned = uniqueIdWasAssignedOnce;
  }

  const jsonTheme = useMemo(() => {
    if (configurationWithUniqueIds.pages.length) {
      return new JsonThemeObject(
        deepmerge(themeDefault, configurationWithUniqueIds),
      );
    }
  }, [template.id]);

  const [liveConfig, setLiveConfig] = useState(jsonTheme?.getTheme());
  const [currentMessage, setCurrentMessage] = useState(
    initialState.currentMessage,
  );
  const [lastSavedConfig, setLastSavedConfig] = useState(
    initialState.lastSavedConfig,
  );
  const [lastPublishedConfig, setLastPublishedConfig] = useState(
    initialState.lastPublishedConfig,
  );
  const [currentDevice, setCurrentDevice] = useState(
    paramDevice || initialState.currentDevice,
  );
  const [loading, setLoading] = useState(initialState.loading);
  const [fitContentEnabled, setFitContentEnabled] = useState(
    initialState.fitContentEnabled,
  );
  const [canvasValues, setCanvasValues] = useState(initialState.canvasValues);
  const [mocks, setMocks] = useState(initialState.mocks);
  const [selectorEnabled, setSelectorEnabled] = useState(
    initialState.selectorEnabled,
  );

  function addMock(mockKey: MockKey) {
    setMocks((currentMocks) => ({
      ...currentMocks,
      [mockKey]: allMocks[mockKey],
    }));
  }

  function removeMock(mockKey: MockKey) {
    const currentMocks = { ...mocks };
    delete currentMocks[mockKey];
    setMocks(currentMocks);
  }

  useEffect(() => {
    jsonTheme?.addObserver(() => {
      setLiveConfig({ ...jsonTheme?.getTheme() });
    });
  }, [jsonTheme]);

  const context = useMemo(() => {
    const unsavedChanges =
      lastSavedConfig && !deepEqual(lastSavedConfig, liveConfig);
    const unpublishedChanges =
      unsavedChanges ||
      (lastPublishedConfig && !deepEqual(lastPublishedConfig, lastSavedConfig));
    return {
      jsonTheme,
      liveConfig,
      unsavedChanges,
      unpublishedChanges,
    };
  }, [jsonTheme, liveConfig, lastSavedConfig, lastPublishedConfig]);

  const messages = useEditorMessages({ marketplaceId, setCurrentMessage });

  return (
    <EditorCtx.Provider
      value={{
        liveConfig: liveConfig || undefined,
        jsonTheme: context.jsonTheme,
        template,
        uniqueIdWasAssigned,
        isEditor,
        messages,
        setCanvasValues,
        canvasValues,
        mocks,
        addMock,
        removeMock,
        selectorEnabled,
        setSelectorEnabled,
        currentDevice,
        setCurrentDevice,
        loading,
        setLoading,
        fitContentEnabled,
        setFitContentEnabled,
        currentMessage,
        setCurrentMessage,
        lastSavedConfig,
        setLastSavedConfig,
        unsavedChanges: context.unsavedChanges,
        lastPublishedConfig,
        setLastPublishedConfig,
        setLiveConfig,
        unpublishedChanges: context.unpublishedChanges,
      }}
    >
      {children}
    </EditorCtx.Provider>
  );
}
