import { useEffect, useState } from "react";
import { pageMapping } from "../../builder/mapping/page";
import { SSR } from "../../helpers/environment";
import { clearAuthenticationTokens } from "../HTTPClient";
import {
  buildUrlWithQueryParams,
  useHashParams,
  useQueryParams,
} from "../QueryParams";
import {
  User,
  getUser as loadUser,
  getUserByJwt,
  JwtPayload,
} from "../api/user";
import { EventsDispatcherImpl } from "../../helpers/EventDispatcher";
import { isLocalStorageAvailable } from "../../helpers/isLocalStorageAvailable";
import { getNonceKey } from "../SSO";
import { useConfiguration } from "../api/configuration";
import { localStorageWrapper } from "../LocalStorage";
import {
  ACCESS_TOKEN_KEY,
  ACCESS_TOKEN_CLIENT,
  ACCESS_TOKEN_EXPIRY,
  ACCESS_TOKEN_UID,
} from "../constants";
import { decodeState } from "../../helpers/sso";

interface AccessHeaders {
  accessToken?: string;
  client?: string;
  expiry?: string;
  uid?: string;
}
function getAuthorizationHeaders(): AccessHeaders {
  if (SSR || !isLocalStorageAvailable) {
    return {};
  }
  const accessToken =
    localStorageWrapper.getItem(ACCESS_TOKEN_KEY) || undefined;
  const client = localStorageWrapper.getItem(ACCESS_TOKEN_CLIENT) || undefined;
  const expiry = localStorageWrapper.getItem(ACCESS_TOKEN_EXPIRY) || undefined;
  const uid = localStorageWrapper.getItem(ACCESS_TOKEN_UID) || undefined;
  return {
    accessToken,
    client,
    expiry,
    uid,
  };
}

interface UserEvents {
  user: (u: User) => void;
  loading: (l: boolean) => void;
  jwtAuthenticationError: (j: JWTError | null) => void;
}
const userDispatcher = new EventsDispatcherImpl<UserEvents>();

interface GetUserParams {
  jwt?: string;
  cid?: string;
  state?: string;
  invitationEmail?: string;
  invitationToken?: string;
  provider?: string;
  screenshot?: string;
}

export enum JWTErrorCause {
  UNKNOWN,
  MISSMATCH_NONCE,
  MISSMATCH_INVITATION_EMAIL,
}

export interface JWTError {
  cause: JWTErrorCause;
  link?: string;
}

class SessionStorage {
  private user: User | undefined;

  private loading = false;

  private usedJwt = "";

  private usedAccessToken = "";

  private jwtAuthenticationError: JWTError | null = null;

  getUser() {
    return this.user;
  }

  getLoading() {
    return this.loading;
  }

  getUsedJwt() {
    return this.usedJwt;
  }

  getJwtAuthenticationError() {
    return this.jwtAuthenticationError;
  }

  private setLoading(loading: boolean) {
    this.loading = loading;
    userDispatcher.dispatch("loading", this.loading);
  }

  public setjwtAuthenticationError(jwtAuthenticationError: JWTError | null) {
    this.jwtAuthenticationError =
      this.usedJwt !== "" ? jwtAuthenticationError : null;
    userDispatcher.dispatch(
      "jwtAuthenticationError",
      this.jwtAuthenticationError,
    );
  }

  async loadUser({ jwt, cid, state, provider, screenshot }: GetUserParams) {
    if (SSR) {
      return;
    }

    if (jwt && jwt != this.usedJwt && cid) {
      this.usedJwt = jwt;
      clearAuthenticationTokens();
      // TODO: ignore any user load in process
      this.setLoading(true);
      try {
        const options: JwtPayload = { cid, token: jwt, provider, screenshot };
        if (state) {
          const { provider, invitationEmail, invitationToken } =
            decodeState(state);
          const nonceKey = getNonceKey(provider);
          const nonce = localStorageWrapper.getItem(nonceKey);
          localStorageWrapper.removeItem(nonceKey);
          const [, base64Payload] = jwt.split(".");
          const jwtPayload = JSON.parse(window.atob(base64Payload));
          if (invitationEmail && jwtPayload.email !== invitationEmail) {
            this.setjwtAuthenticationError({
              cause: JWTErrorCause.MISSMATCH_INVITATION_EMAIL,
              link: buildUrlWithQueryParams(pageMapping.SignUp.path, {
                invitation_email: invitationEmail,
                invitation_token: invitationToken,
              }),
            });
            throw new Error(
              `The account (${jwtPayload.email}) is not associated to the invitation, please try again with the invited email: (${invitationEmail}).`,
            );
          }
          if (jwtPayload.nonce !== nonce) {
            this.setjwtAuthenticationError({
              cause: JWTErrorCause.MISSMATCH_NONCE,
            });
            throw new Error("Authentication Error: nonce does not match", {
              cause: JWTErrorCause.MISSMATCH_NONCE,
            });
          }
          options.provider = provider;
        }
        this.user = (await getUserByJwt(options)).data;
        this.user && userDispatcher.dispatch("user", this.user);
      } catch (e) {
        // TODO error handling
        console.error("Authentication Error", { jwt }, e);
        if (!(e instanceof Error)) {
          this.setjwtAuthenticationError({
            cause: JWTErrorCause.UNKNOWN,
          });
        }
      } finally {
        this.setLoading(false);
      }
    } else {
      const authHeaders = getAuthorizationHeaders();
      if (authHeaders.accessToken) {
        // TODO: we should probably remove this.user condition in this if
        if (!this.loading && this.usedAccessToken !== authHeaders.accessToken) {
          this.setLoading(true);
          try {
            this.user = await loadUser().json();
            this.user && userDispatcher.dispatch("user", this.user);
          } catch (e) {
            // TODO error handling
          } finally {
            this.usedAccessToken = authHeaders.accessToken;
            this.setLoading(false);
          }
        }
      }
    }
    return this.user;
  }
}

export const sessionStorage = new SessionStorage();

export const useSession = () => {
  const { jwt, cid, provider, screenshot } = useQueryParams();
  const { configuration } = useConfiguration();
  const { id_token, state, error: jwtError } = useHashParams();
  const [user, setUser] = useState<User | undefined>(sessionStorage.getUser());
  const [loading, setLoading] = useState<boolean>(sessionStorage.getLoading());
  const [jwtAuthenticationError, setJwtAuthenticationError] =
    useState<JWTError | null>(
      sessionStorage.getUsedJwt() !== "" || !!jwtError
        ? sessionStorage.getJwtAuthenticationError()
        : null,
    );
  userDispatcher.on("user", (user) => setUser(user));
  userDispatcher.on("loading", (l) => {
    setLoading(l);
  });
  userDispatcher.on("jwtAuthenticationError", (j) => {
    setJwtAuthenticationError(j);
  });
  sessionStorage.loadUser({
    jwt: jwt || id_token,
    cid: cid || configuration?.id,
    state,
    provider,
    screenshot,
  });
  useEffect(() => () => {
    userDispatcher.off("user", setUser);
    userDispatcher.off("loading", setLoading);
    userDispatcher.off("jwtAuthenticationError", setJwtAuthenticationError);
  });

  return {
    user,
    loading,
    jwtAuthenticationError,
  };
};
