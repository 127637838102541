import { useRouter } from "next/router";
import { Dispatch, SetStateAction } from "react";
import { UrlObject } from "url";
import { useUser } from "../dataAccess/api/user";
import { useEditor } from "../wysiwyg/EditorCtx";
import type { ManagerJwtResponse } from "../dataAccess/api/managers";

export const managerAppMarketplacePagePath = "partner/marketplaces";
export const builtfirstCommunitySubdomain = "marketplace";
export interface RouterPushOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
  unstable_skipClientCache?: boolean;
}

export function useNavigateSameTab() {
  const router = useRouter();
  return (url: UrlObject | string) =>
    router.push(url, undefined, { shallow: true });
}

export function useNavigateNewTabSync() {
  return (url: string) => {
    window.open(url, "_blank");
  };
}

export function useNavigateSameTabExternal() {
  return (url: string) => {
    window.open(url, "_self");
  };
}

export function scrollIntoView({
  element,
  onEnd,
  scrollLogicalPosition = "center",
}: {
  element?: Element | null;
  onEnd?: () => void;
  scrollLogicalPosition?: ScrollLogicalPosition;
}) {
  if (element && onEnd) {
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setTimeout(() => {
            onEnd();
            intersectionObserver.unobserve(element);
          }, 100);
        }
      },
      {
        threshold: 1.0,
      },
    );
    intersectionObserver.observe(element);
  }
  element?.scrollIntoView &&
    element.scrollIntoView({
      behavior: "smooth",
      block: scrollLogicalPosition,
    });
}

export class BrowserTab {
  private windowReference: Window | undefined;

  public open() {
    this.windowReference = window.open("about:blank", "_blank") || undefined;
  }

  public setLocation(url: string) {
    if (this.windowReference) {
      this.windowReference.location = url;
    } else {
      console.error("BrowserTab setLocation called without window reference");
    }
  }

  public close() {
    this.windowReference?.close();
  }
}

export const useNavigateToManagerAppByJwt = (
  setIsManagerJwtLoading: Dispatch<SetStateAction<boolean>>,
) => {
  const navigateSameTabExternal = useNavigateSameTabExternal();
  const navigateNewTab = useNavigateNewTabSync();
  const { manageRetUrl } = useEditor();
  const { user } = useUser();

  async function navigateToManagerAppByJwt(
    jwtRequest: () => Promise<ManagerJwtResponse>,
    path?: string,
    options?: { target?: "_blank"; hash?: string },
  ) {
    const openNew = options?.target === "_blank";
    const tab = new BrowserTab();
    try {
      const authenticated =
        !user?.guestUser &&
        user?.confirmed &&
        user?.organization?.allowedInMarketplace;

      if (!authenticated) {
        const url = process.env.NEXT_PUBLIC_MANAGER_APP_URL || "";
        return openNew ? navigateNewTab(url) : navigateSameTabExternal(url);
      }

      setIsManagerJwtLoading(true);

      if (openNew) {
        tab.open();
      }

      const response = await jwtRequest();
      const redirectionPath = manageRetUrl || path;

      const url = `${process.env.NEXT_PUBLIC_MANAGER_APP_URL}${
        redirectionPath || ""
      }${path?.includes("?") ? "&" : "?"}jwt=${response.jwt}${
        options?.hash || ""
      }`;

      if (openNew) {
        tab.setLocation(url);
      } else {
        navigateSameTabExternal(url);
      }

      setIsManagerJwtLoading(false);

      return response;
    } catch (e) {
      setIsManagerJwtLoading(false);
      tab.close();
      console.error(e);
    }
  }
  return { navigateToManagerAppByJwt };
};
