import { Button } from "@mui/material";
import {
  Dispatch,
  MouseEvent,
  ReactNode,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import AlertDialog from "../components/designSystem/AlertDialog";
import { useNavigateToManagerAppByJwt } from "../helpers/navigation";
import {
  buildManageMarketplacePath,
  editorAccessedOnceAtLeastKey,
} from "./EditorCtx";
import { ButtonProgress } from "../components/ui/ButtonProgress";
import type { ManagerJwtResponse } from "../dataAccess/api/managers";
import { localStorageWrapper } from "../dataAccess/LocalStorage";

let getManagerJwtAuth: () => Promise<ManagerJwtResponse>;

import("../dataAccess/api/managers").then((module) => {
  getManagerJwtAuth = module.getManagerJwtAuth;
});

export const unsavedChangesMessage =
  "You have unsaved changes. Are you sure you want to leave?";

export const redirectToSettingsMessage =
  "Do you want to be redirected to Settings? Note, if your changes haven’t been saved, please do so before selecting to leave.";

export function useEditorMessages({
  marketplaceId,
  setCurrentMessage,
}: {
  marketplaceId?: string;
  setCurrentMessage: Dispatch<SetStateAction<ReactNode | undefined>>;
}): MessageList {
  const handleCloseMessage = () => setCurrentMessage(undefined);
  const [managerJwtLoading, setManagerJwtLoading] = useState(false);
  const { navigateToManagerAppByJwt } =
    useNavigateToManagerAppByJwt(setManagerJwtLoading);

  const handleGoToManageMarketplace = (
    e: MouseEvent<HTMLButtonElement>,
    path?: string,
    hash?: string,
  ) => {
    window.onbeforeunload = null;
    navigateToManagerAppByJwt(
      getManagerJwtAuth,
      buildManageMarketplacePath(marketplaceId, path),
      { hash },
    );
  };

  const close = (
    <AlertDialog
      severity="warning"
      title={unsavedChangesMessage}
      onClose={handleCloseMessage}
      renderActions={() => (
        <>
          <Button onClick={handleCloseMessage} variant="outlined">
            Stay here
          </Button>
          <Button
            endIcon={managerJwtLoading ? <ButtonProgress /> : null}
            disabled={managerJwtLoading}
            variant="contained"
            onClick={handleGoToManageMarketplace}
          >
            Leave
          </Button>
        </>
      )}
    />
  );

  const previewAlert = (
    <AlertDialog
      severity="info"
      title="This action can only be performed in your marketplace and not in the editor."
      onClose={handleCloseMessage}
    />
  );

  const handleCloseUnderConstruction = useCallback(() => {
    localStorageWrapper.setItem(editorAccessedOnceAtLeastKey, "true");
    handleCloseMessage();
  }, [handleCloseMessage]);

  const underConstruction = (
    <AlertDialog
      severity="info"
      title="Editor under construction"
      description={
        <>
          Look forward to more customization and functionality. Your feedback
          drives on-going improvements.
        </>
      }
      onClose={handleCloseUnderConstruction}
      renderActions={() => (
        <>
          <Button
            endIcon={managerJwtLoading ? <ButtonProgress /> : null}
            disabled={managerJwtLoading}
            onClick={handleGoToManageMarketplace}
            variant="outlined"
          >
            GO BACK
          </Button>
          <Button variant="contained" onClick={handleCloseUnderConstruction}>
            CONTINUE
          </Button>
        </>
      )}
    />
  );

  const publishedSuccess = (
    <AlertDialog
      severity="success"
      title="Done! Your changes may take a few minutes to go live after users click “Publish”."
      onClose={handleCloseMessage}
    />
  );

  const editBranding = (
    <AlertDialog
      severity="info"
      description={
        <>
          You can modify the &quot;Powered by Builtfirst&quot; label in Settings
          under &quot;
          <strong>Advanced Branding</strong>
          &quot; section. {redirectToSettingsMessage}
        </>
      }
      onClose={handleCloseMessage}
      renderActions={() => (
        <>
          <Button onClick={handleCloseMessage} variant="outlined">
            Close
          </Button>
          <Button
            startIcon={managerJwtLoading ? <ButtonProgress /> : null}
            variant="contained"
            onClick={(e) =>
              handleGoToManageMarketplace(e, "/settings", "#branding")
            }
          >
            Leave
          </Button>
        </>
      )}
    />
  );

  const duplicatedSuccess = (
    <AlertDialog
      severity="success"
      title="Your template was successfuly duplicated"
      description={
        <>
          You can find it in &quot;<strong>Your Marketplace Templates</strong>
          &quot; section. {redirectToSettingsMessage}
        </>
      }
      onClose={handleCloseMessage}
      renderActions={() => (
        <>
          <Button onClick={handleCloseMessage} variant="outlined">
            Close
          </Button>
          <Button
            endIcon={managerJwtLoading ? <ButtonProgress /> : null}
            disabled={managerJwtLoading}
            variant="contained"
            onClick={handleGoToManageMarketplace}
          >
            Go To Section
          </Button>
        </>
      )}
    />
  );

  return {
    close,
    previewAlert,
    underConstruction,
    duplicatedSuccess,
    editBranding,
    publishedSuccess,
  };
}

export interface MessageList {
  close: ReactNode;
  previewAlert: ReactNode;
  underConstruction: ReactNode;
  duplicatedSuccess: ReactNode;
  editBranding: ReactNode;
  publishedSuccess: ReactNode;
}
