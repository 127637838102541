import { useRouter } from "next/router";
import { createContext, PropsWithChildren, useEffect, useMemo } from "react";
import { SSO_REDIRECT_PARAM } from "../dataAccess/constants";
import { pageMapping } from "../builder/mapping/page";
import { User } from "../dataAccess/api/user";
import { JWTError, useSession } from "../dataAccess/storages/session";
import {
  useHash,
  useHashParams,
  useQueryParams,
} from "../dataAccess/QueryParams";
import { decodeState } from "../helpers/sso";

interface Ctx {
  user?: User;
  loading: boolean;
  jwtAuthenticationError: JWTError | null;
}

const initialState = {
  loading: false,
  jwtAuthenticationError: null,
};

export const UserCtx = createContext<Ctx>(initialState);

export function UserProvider({ children }: PropsWithChildren) {
  const { jwt } = useQueryParams();
  const { hash } = useHash();
  const { id_token, state } = useHashParams();
  const { loading, user, jwtAuthenticationError } = useSession();
  const router = useRouter();

  const context = useMemo(
    () => ({
      user,
      loading,
      jwtAuthenticationError,
    }),
    [user, loading, jwtAuthenticationError],
  );

  useEffect(() => {
    if (jwt) {
      const { jwt, cid, ...params } = router.query;
      router.query = { ...params };
      router.replace(router, undefined, {
        shallow: true,
      });
    }
    if (id_token) {
      let { retUrl } = decodeState(state || "");
      if (
        retUrl?.includes(pageMapping.Login.path) ||
        retUrl?.includes(pageMapping.SignUp.path)
      ) {
        retUrl = "/";
      }
      const currentPath = retUrl || router.asPath.replace(hash, "");
      const { [SSO_REDIRECT_PARAM]: ssoredirect, ...params } = router.query;
      router.asPath = currentPath;
      router.query = { ...params };
      router.replace(router, undefined, { shallow: true });
    }
  }, []);

  return <UserCtx.Provider value={context}>{children}</UserCtx.Provider>;
}
