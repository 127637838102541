import type { JSONObject, JSONSchema, RecursiveRequired } from "../ThemeSchema";
import { theme } from "./theme";
import type { ThemeConfiguration } from "../JsonThemeObject";

export const themeSchema: JSONObject<JSONSchema<ThemeConfiguration>> & {
  $schema: string;
} = {
  $schema: "http://json-schema.org/draft-07/schema#",
  type: "object",
  properties: {
    uniqueId: { type: "string" },
    pages: {
      type: "array",
      items: {
        settings: { type: "object", properties: {} },
        uniqueId: { type: "string" },
        id: {
          type: "string",
          enum: [
            "CookiesHelper",
            "ForgotPassword",
            "Login",
            "PDP",
            "PLP",
            "ResetPassword",
            "SignUp",
            "VerifyEmail",
            "QuoteSubmit",
          ],
        },
      },
    },
    settings: theme.schema,
  },
};

export const themeDefault: RecursiveRequired<ThemeConfiguration> = {
  pages: [],
  settings: theme.settings,
  uniqueId: "theme",
};
