import { Alert } from "@mui/material";
import type { ThemeSettings } from "../../types/theme";
import type { RecursiveRequired, SettingsSchema } from "../ThemeSchema";

import type { PageMapping } from "../../builder/mapping/page";
import type { AvailableComponents as AvailableComponentsPLP } from "../../components/pages/PLP";
import type { AvailableComponents as AvailableComponentsPDP } from "../../components/pages/PDP";
import type { AvailableComponents as AvailableComponentsQuoteDetails } from "../../components/pages/QuoteSubmit";
import type { AvailableComponents as AvailableComponentsCookiesHelper } from "../../components/pages/CookiesHelper";
import type { AvailableComponents as AvailableComponentsForgotPassword } from "../../components/pages/ForgotPassword";
import type { AvailableComponents as AvailableComponentsLogin } from "../../components/pages/LogIn";
import type { AvailableComponents as AvailableComponentsResetPassword } from "../../components/pages/ResetPassword";
import type { AvailableComponents as AvailableComponentsSignUp } from "../../components/pages/SignUp";
import type { AvailableComponents as AvailableComponentsVerifyEmail } from "../../components/pages/VerifyEmail";
import type { ComponentType } from "../JsonThemeObject";

import {
  defaultColorsConstant as defaultColors,
  defaultFontsConstant,
} from "../../../theme/defaultValues";

export const defaultIconSet = "outlined";

const settings: RecursiveRequired<ThemeSettings> = {
  colors: {
    primary: "#3873ff",
    secondary: "#000",
    validation: {
      successColor: defaultColors.validationSuccess,
      warningColor: defaultColors.validationWarning,
      errorColor: defaultColors.validationError,
    },
    pages: {
      backgroundColor: "#fff",
      textColor: "#626262",
    },
    panels: {
      backgroundColor: "#fff",
      textColor: "#626262",
    },
    tiles: {
      backgroundColor: defaultColors.whiteDefault,
      textColor: "#626262",
      othersColor: defaultColors.validationSuccess,
    },
    chips: {
      backgroundColor: "#fff",
      textColor: "#626262",
    },
    buttons: {
      backgroundColor: "#3873ff",
      textColor: "#fff",
      borderColor: "",
      secondaryButtons: {
        backgroundColor: "",
        textColor: "",
        borderColor: "",
      },
    },
    inputs: {
      backgroundColor: "#fff",
      textColor: "#626262",
      borderColor: "#cccccc",
    },
    other: "",
  },
  fonts: {
    baseSize: "medium",
    headers: {
      fontFamily: defaultFontsConstant.headers,
      size: "medium",
    },
    body: {
      fontFamily: defaultFontsConstant.body,
      size: "medium",
    },
    inputs: {
      size: "medium",
    },
    chips: {
      size: "medium",
    },
    buttons: {
      size: "medium",
    },
  },
  inputs: {
    size: "medium",
    border: {
      opacity: 1,
      radius: 4,
      thickness: 0,
    },
    showUnderline: false,
    capitalization: "Ag",
  },
  pagesTitlePrefix: "Page title prefix",
  hideSupportChat: false,
  seeMore: "plus",
  hideScrollBar: false,
  favicon: "/favicon.svg",
  tiles: {
    type: "standard",
    hoverVariant: "border",
    spacing: "medium",
    padding: 12.8,
    elegantBackgroundHoverColor: "",
    elegantTextHoverColor: "",
    titleContent: {
      showListingName: false,
      showSavingAmount: true,
      showVendorName: true,
    },
    bodyContent: {
      partnerDescription: true,
      dealDescription: true,
    },
    border: {
      opacity: 1,
      radius: 4,
      thickness: 1,
    },
    shadows: {
      opacity: 0.5,
      horizontalOffset: 0,
      verticalOffset: 2,
      blur: 4,
    },
    categories: true,
    recommendations: true,
    titlesMultipleRows: false,
  },
  logos: {
    border: {
      opacity: 1,
      radius: 12,
      thickness: 2,
    },
    shadows: {
      opacity: 0,
      horizontalOffset: 0,
      verticalOffset: 0,
      blur: 8,
    },
  },
  panels: {
    border: {
      opacity: 1,
      radius: 1.2,
      thickness: 0,
    },
    shadows: {
      opacity: 0.1,
      horizontalOffset: 0,
      verticalOffset: 0,
      blur: 16,
    },
  },
  chips: {
    size: "medium",
    border: {
      opacity: 1,
      radius: 4,
      thickness: 1,
    },
    shadows: {
      opacity: 0,
      horizontalOffset: 0,
      verticalOffset: 0,
      blur: 0,
    },
    capitalization: "Ag",
  },
  buttons: {
    size: "medium",
    border: {
      opacity: 1,
      radius: 100,
      thickness: 1,
    },
    shadows: {
      opacity: 0.5,
      horizontalOffset: 0,
      verticalOffset: 2,
      blur: 4,
    },
    capitalization: "AG",
    secondaryButtons: {
      useSecondaryButtons: false,
      size: "medium",
      border: {
        opacity: 1,
        radius: 100,
        thickness: 1,
      },
      shadows: {
        opacity: 0.5,
        horizontalOffset: 0,
        verticalOffset: 2,
        blur: 4,
      },
      capitalization: "AG",
    },
  },
  layout: {
    // maxWidth breakpoints from https://mui.com/material-ui/customization/breakpoints/
    mobile: {
      top: "1rem",
      bottom: "1rem",
      maxWidth: "1200px",
    },
    tablet: {
      top: "2rem",
      bottom: "4rem",
      maxWidth: "1200px",
    },
    desktop: {
      top: "2rem",
      bottom: "4rem",
      maxWidth: "1200px",
    },
    large: {
      top: "2rem",
      bottom: "4rem",
      maxWidth: "1200px",
    },
    extra: {
      top: "2rem",
      bottom: "4rem",
      maxWidth: "1536px",
    },
  },
  lockScreens: {
    alignment: "left",
    maxWidth: 800,
    graphic: {
      html: "",
      backgroundColor: "#fff",
      variant: "html",
      textColor: "",
    },
  },
  backButtonText: "Previous",
  iconSet: defaultIconSet,
  carousels: {
    variant: "both",
  },
};

const schema: SettingsSchema<ThemeSettings> = {
  type: "object",
  properties: {
    layout: {
      type: "object",
      properties: {
        mobile: {
          type: "object",
          properties: {
            top: { type: "string" },
            bottom: { type: "string" },
            maxWidth: { type: "string" },
          },
          "x-metadata": {
            title: "",
            description: "",
            devices: ["mobile"],
          },
        },
        tablet: {
          type: "object",
          properties: {
            top: { type: "string" },
            bottom: { type: "string" },
            maxWidth: { type: "string" },
          },
          "x-metadata": {
            title: "",
            description: "",
            devices: ["tablet"],
          },
        },
        desktop: {
          type: "object",
          properties: {
            top: { type: "string" },
            bottom: { type: "string" },
            maxWidth: { type: "string" },
          },
          "x-metadata": {
            title: "",
            description: "",
            devices: ["tablet"],
          },
        },
        large: {
          type: "object",
          properties: {
            top: { type: "string" },
            bottom: { type: "string" },
            maxWidth: { type: "string" },
          },
          "x-metadata": {
            title: "",
            description: "",
            devices: ["desktop"],
          },
        },
        extra: {
          type: "object",
          properties: {
            top: { type: "string" },
            bottom: { type: "string" },
            maxWidth: { type: "string" },
          },
          "x-metadata": {
            title: "",
            description: "",
            devices: ["desktop"],
          },
        },
      },
    },
    carousels: {
      type: "object",
      properties: {
        variant: { type: "string", enum: ["arrows", "both", "bullets"] },
      },
    },
    colors: {
      type: "object",
      properties: {
        primary: {
          type: "string",
          "x-metadata": {
            description: "Shown in chips, buttons, icons, and titles",
            title: "Primary Color",
            variant: "color",
          },
        },
        secondary: {
          type: "string",
          "x-metadata": {
            description: "Shown in carousel arrows and pagination",
            title: "Secondary Color",
            variant: "color",
          },
        },
        pages: {
          type: "object",
          properties: {
            backgroundColor: {
              type: "string",
            },
            textColor: {
              type: "string",
            },
          },
        },
        buttons: {
          type: "object",
          properties: {
            textColor: {
              type: "string",
            },
            backgroundColor: {
              type: "string",
            },
            borderColor: {
              type: "string",
            },
            secondaryButtons: {
              type: "object",
              properties: {
                textColor: {
                  type: "string",
                },
                backgroundColor: {
                  type: "string",
                },
                borderColor: {
                  type: "string",
                },
              },
              "x-metadata": {
                title: "Secondary Buttons",
                description: "Secondary variant for Buttons",
              },
            },
          },
        },
        tiles: {
          type: "object",
          properties: {
            backgroundColor: {
              type: "string",
            },
            textColor: {
              type: "string",
            },
            othersColor: {
              type: "string",
            },
          },
        },
        chips: {
          type: "object",
          properties: {
            backgroundColor: {
              type: "string",
            },
            textColor: {
              type: "string",
            },
          },
        },
        inputs: {
          type: "object",
          properties: {
            backgroundColor: {
              type: "string",
            },
            textColor: {
              type: "string",
            },
            borderColor: {
              type: "string",
            },
          },
        },
        panels: {
          type: "object",
          properties: {
            textColor: {
              type: "string",
            },
            backgroundColor: {
              type: "string",
            },
          },
        },
        validation: {
          type: "object",
          properties: {
            successColor: {
              type: "string",
            },
            warningColor: {
              type: "string",
            },
            errorColor: {
              type: "string",
            },
          },
          "x-metadata": {
            title: "Validation",
            description: "Severity of errors, warnings and success messages",
            preComponent: () => (
              <Alert severity="warning">
                These colors symbolize the severity levels of errors, warnings,
                and success messages. Modifying these colors could potentially
                lead to confusion for your customers when interacting with your
                marketplace.
                <a href="?editor_feature-internal=Header.2517917115">
                  Header.2517917115
                </a>
              </Alert>
            ),
          },
        },
        other: {
          type: "string",
          "x-metadata": {
            title: "Other",
            description: "Shown in clear filters button",
            variant: "color",
          },
        },
      },
    },
    fonts: {
      type: "object",
      properties: {
        baseSize: {
          type: "string",
          enum: ["extraSmall", "small", "medium", "large", "extraLarge"],
        },
        headers: {
          type: "object",
          properties: {
            fontFamily: {
              type: "string",
              "x-metadata": {
                title: "Headers font",
                description: "his font is used for the titles",
                variant: "font",
              },
            },
            size: {
              type: "string",
              enum: ["extraSmall", "small", "medium", "large", "extraLarge"],
            },
          },
        },
        body: {
          type: "object",
          properties: {
            fontFamily: {
              type: "string",
              "x-metadata": {
                title: "Body font",
                description:
                  "This font is used for all text content except titles.",
                variant: "font",
              },
            },
            size: {
              type: "string",
              enum: ["extraSmall", "small", "medium", "large", "extraLarge"],
            },
          },
        },
        inputs: {
          type: "object",
          properties: {
            size: {
              type: "string",
              enum: ["extraSmall", "small", "medium", "large", "extraLarge"],
            },
          },
        },
        chips: {
          type: "object",
          properties: {
            size: {
              type: "string",
              enum: ["extraSmall", "small", "medium", "large", "extraLarge"],
            },
          },
        },
        buttons: {
          type: "object",
          properties: {
            size: {
              type: "string",
              enum: ["extraSmall", "small", "medium", "large", "extraLarge"],
            },
          },
        },
      },
    },
    inputs: {
      type: "object",
      properties: {
        size: {
          type: "string",
          enum: ["extraSmall", "small", "medium", "large", "extraLarge"],
        },
        border: {
          type: "object",
          properties: {
            opacity: {
              type: "number",
            },
            radius: {
              type: "number",
            },
            thickness: {
              type: "number",
            },
          },
        },
        showUnderline: {
          type: "boolean",
        },
        capitalization: {
          type: "string",
          enum: ["AG", "Ag", "ag"],
        },
      },
    },
    tiles: {
      type: "object",
      properties: {
        type: {
          type: "string",
          enum: ["standard", "stacked", "plain", "compacted", "elegant"],
        },
        elegantBackgroundHoverColor: {
          type: "string",
          "x-metadata": {
            devices: [],
            title: "Elegant Background Hover Color",
            description:
              "Determinates the color of the background when variant is elegant and hovered.",
          },
        },
        elegantTextHoverColor: {
          type: "string",
          "x-metadata": {
            devices: [],
            title: "Elegant Text Hover Color",
            description:
              "Determinates the color of the text when variant is elegant and hovered. ",
          },
        },
        hoverVariant: {
          type: "string",
          enum: ["border", "background", "both"],
        },
        spacing: {
          type: "string",
          enum: ["extraSmall", "small", "medium", "large", "extraLarge"],
        },
        padding: {
          type: "number",
        },
        titleContent: {
          type: "object",
          properties: {
            showListingName: {
              type: "boolean",
            },
            showSavingAmount: {
              type: "boolean",
            },
            showVendorName: {
              type: "boolean",
            },
          },
        },
        bodyContent: {
          type: "object",
          properties: {
            partnerDescription: {
              type: "boolean",
            },
            dealDescription: {
              type: "boolean",
            },
          },
        },
        border: {
          type: "object",
          properties: {
            opacity: {
              type: "number",
            },
            radius: {
              type: "number",
            },
            thickness: {
              type: "number",
            },
          },
        },
        shadows: {
          type: "object",
          properties: {
            opacity: {
              type: "number",
            },
            horizontalOffset: {
              type: "number",
            },
            verticalOffset: {
              type: "number",
            },
            blur: {
              type: "number",
            },
          },
        },
        categories: {
          type: "boolean",
        },
        recommendations: {
          type: "boolean",
        },
        titlesMultipleRows: {
          type: "boolean",
        },
      },
    },
    panels: {
      type: "object",
      properties: {
        border: {
          type: "object",
          properties: {
            opacity: {
              type: "number",
            },
            radius: {
              type: "number",
            },
            thickness: {
              type: "number",
            },
          },
        },
        shadows: {
          type: "object",
          properties: {
            opacity: {
              type: "number",
            },
            horizontalOffset: {
              type: "number",
            },
            verticalOffset: {
              type: "number",
            },
            blur: {
              type: "number",
            },
          },
        },
      },
    },
    logos: {
      type: "object",
      properties: {
        border: {
          type: "object",
          properties: {
            opacity: {
              type: "number",
            },
            radius: {
              type: "number",
            },
            thickness: {
              type: "number",
            },
          },
        },
        shadows: {
          type: "object",
          properties: {
            opacity: {
              type: "number",
            },
            horizontalOffset: {
              type: "number",
            },
            verticalOffset: {
              type: "number",
            },
            blur: {
              type: "number",
            },
          },
        },
      },
    },
    chips: {
      type: "object",
      properties: {
        size: {
          type: "string",
          enum: ["extraSmall", "small", "medium", "large", "extraLarge"],
        },
        border: {
          type: "object",
          properties: {
            opacity: {
              type: "number",
            },
            radius: {
              type: "number",
            },
            thickness: {
              type: "number",
            },
          },
        },
        shadows: {
          type: "object",
          properties: {
            opacity: {
              type: "number",
            },
            horizontalOffset: {
              type: "number",
            },
            verticalOffset: {
              type: "number",
            },
            blur: {
              type: "number",
            },
          },
        },
        capitalization: {
          type: "string",
          enum: ["AG", "Ag", "ag"],
        },
      },
    },
    buttons: {
      type: "object",
      properties: {
        size: {
          type: "string",
          enum: ["extraSmall", "small", "medium", "large", "extraLarge"],
        },
        border: {
          type: "object",
          properties: {
            opacity: {
              type: "number",
            },
            radius: {
              type: "number",
            },
            thickness: {
              type: "number",
            },
          },
        },
        shadows: {
          type: "object",
          properties: {
            opacity: {
              type: "number",
            },
            horizontalOffset: {
              type: "number",
            },
            verticalOffset: {
              type: "number",
            },
            blur: {
              type: "number",
            },
          },
        },
        capitalization: {
          type: "string",
          enum: ["AG", "Ag", "ag"],
        },
        secondaryButtons: {
          type: "object",
          properties: {
            useSecondaryButtons: { type: "boolean" },
            size: {
              type: "string",
              enum: ["extraSmall", "small", "medium", "large", "extraLarge"],
            },
            border: {
              type: "object",
              properties: {
                opacity: {
                  type: "number",
                },
                radius: {
                  type: "number",
                },
                thickness: {
                  type: "number",
                },
              },
            },
            shadows: {
              type: "object",
              properties: {
                opacity: {
                  type: "number",
                },
                horizontalOffset: {
                  type: "number",
                },
                verticalOffset: {
                  type: "number",
                },
                blur: {
                  type: "number",
                },
              },
            },
            capitalization: {
              type: "string",
              enum: ["AG", "Ag", "ag"],
            },
          },
        },
      },
    },
    lockScreens: {
      type: "object",
      properties: {
        alignment: {
          type: "string",
          enum: ["left", "center", "right"],
        },
        maxWidth: {
          type: "number",
        },
        graphic: {
          type: "object",
          properties: {
            variant: { type: "string", enum: ["html", "static"] },
            backgroundColor: { type: "string" },
            html: { type: "string" },
            textColor: { type: "string" },
          },
        },
      },
    },
    hideSupportChat: { type: "boolean" },
    hideScrollBar: { type: "boolean" },
    iconSet: {
      type: "string",
      enum: ["outlined", "filled", "rounded", "sharp", "twoTone"],
    },
    seeMore: {
      type: "string",
      enum: ["plus", "ellipsis"],
    },
    favicon: { type: "string" },
    pagesTitlePrefix: { type: "string" },
    backButtonText: { type: "string" },
  },
};

const PLPSubcomponents: (keyof AvailableComponentsPLP)[] = [
  "BannerWithLogo",
  "BannerWithTextCustomHTML",
  "BannerManageProfileSignUp",
  "BannerWithTextWithImage",
  "Grid",
  "Profile",
];

const PDPSubcomponents: (keyof AvailableComponentsPDP)[] = [
  "BannerWithLogo",
  "BannerWithTextCustomHTML",
  "BannerManageProfileSignUp",
  "BannerWithTextWithImage",
  "TileBanner",
  "DealDetailsLayout",
  "Profile",
];

const QuoteDetailsSubcomponents: (keyof AvailableComponentsQuoteDetails)[] = [
  "QuoteForm",
  "QuoteHistory",
];

const CookiesHelperSubcomponents: (keyof AvailableComponentsCookiesHelper)[] =
  [];
const ForgotPasswordSubcomponents: (keyof AvailableComponentsForgotPassword)[] =
  [];
const LoginSubcomponents: (keyof AvailableComponentsLogin)[] = [];
const ResetPasswordSubcomponents: (keyof AvailableComponentsResetPassword)[] =
  [];
const SignUpSubcomponents: (keyof AvailableComponentsSignUp)[] = [];
const VerifyEmailSubcomponents: (keyof AvailableComponentsVerifyEmail)[] = [];

const pages: Record<keyof PageMapping, ComponentType[]> = {
  PLP: PLPSubcomponents,
  PDP: PDPSubcomponents,
  QuoteSubmit: QuoteDetailsSubcomponents,
  CookiesHelper: CookiesHelperSubcomponents,
  ForgotPassword: ForgotPasswordSubcomponents,
  Login: LoginSubcomponents,
  ResetPassword: ResetPasswordSubcomponents,
  SignUp: SignUpSubcomponents,
  VerifyEmail: VerifyEmailSubcomponents,
};

export const theme = { settings, schema, pages };
