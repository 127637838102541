import { useTheme } from "@mui/material";
import { PropsWithChildren } from "react";
import { IntercomProvider } from "react-use-intercom";
import { useUser } from "../dataAccess/api/user";
import { IntercomInnerWrapper } from "./IntercomInnerWrapper";

export function IntercomWrapper({ children }: PropsWithChildren) {
  const theme = useTheme();
  const { user } = useUser();
  return (
    <IntercomProvider
      autoBoot
      appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID || ""}
      initializeDelay={3500}
      autoBootProps={{
        backgroundColor: theme.palette.primary.main,
        name: user?.firstName ? user?.firstName : "", // TODO: Move this to a different file
        actionColor: theme.palette.primary.main,
      }}
    >
      <IntercomInnerWrapper>{children}</IntercomInnerWrapper>
    </IntercomProvider>
  );
}
