import { useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useContext, useMemo } from "react";
import { iframe } from "../helpers/environment";
import { EditorCtx } from "../wysiwyg/EditorCtx";

export const GlobalStyles = () => {
  const theme = useTheme();
  const { liveConfig } = useContext(EditorCtx);
  const style = useMemo(
    () => (
      <style>
        {(() => `
          * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
          }
          
          html, body, #root, #__next {
            height: ${!iframe ? "100%" : "auto"};
            min-height: 300px;
          }
          
          ::-webkit-scrollbar-track {
            background: none;
          }
          
          ::-webkit-scrollbar-thumb {
            background: ${grey[300]};
            border-radius: 1rem;
          }
          
          ::-webkit-scrollbar-thumb:hover {
            background: ${grey[300]};
          }
        `)()}
      </style>
    ),
    [theme, liveConfig],
  );
  return style;
};
