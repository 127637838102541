export const ACCESS_TOKEN_KEY = "Access-Token";
export const ANALYTICS_UID = "Analytics-Uid";
export const ACCESS_TOKEN_CLIENT = "Client";
export const ACCESS_TOKEN_EXPIRY = "Expiry";
export const ACCESS_TOKEN_UID = "uid";
export const INVITATION_TOKEN = "invitation-token";
export const LOGGED_IN_COOKIE_NAME = "logged-in-user";
export const LOGGED_IN_COOKIE_VALUE = "true";

export const NO_CONTENT_STATUS_CODE = 204;
export const UNAUTHORIZED_STATUS_CODE = 401;
export const DOWNTIME_STATUS_CODE = 503;

export const LOGIN_SUCCESS_POPUP_MESSAGE = "login_success";

export const SSO_POPUP_NAME = "BuiltfirstSSOWindow";

export const SSO_REDIRECT_PARAM = "ssoredirect";
