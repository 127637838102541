import { jsonParseSafe, jsonStringifySafe } from "../../helpers/formatter";
import { localStorageWrapper } from "../LocalStorage";

export const analyticsDataKey = "analytics-data";

interface AnalyticsData {
  analyticsUid?: string;
  pageHasChanged?: string;
  lastPageVisited?: string;
  lastDealVisited?: string;
  lastDealVisitedWasBlurred?: string;
  lastQuery?: string;
}

export function getAnalyticsData(): AnalyticsData {
  return (
    jsonParseSafe(localStorageWrapper.getItem(analyticsDataKey) || "") || {}
  );
}

export function setAnalyticsDataField(
  field: keyof AnalyticsData,
  value?: string,
) {
  const data = getAnalyticsData();
  data[field] = value;
  localStorageWrapper.setItem(analyticsDataKey, jsonStringifySafe(data) || "");
}

export function setAnalyticsPageData() {
  const { lastPageVisited } = getAnalyticsData();
  const ignoredPaths = /\/quote/;
  const pathname = window.location.pathname.replace(ignoredPaths, "");
  if (pathname !== lastPageVisited) {
    setAnalyticsDataField("pageHasChanged", "true");
    setAnalyticsDataField("lastPageVisited", pathname);
  }
}
