import { simpleHash, jsonStringifySafe } from "../helpers/formatter";
import type {
  ThemeComponent,
  JsonSettingValue,
  JsonThemeObject,
  ThemeConfiguration,
} from "./JsonThemeObject";

interface Delta {
  type: "saveSetting" | "removeComponent" | "addComponent";
  parameters: {
    uniqueId?: string;
    setting?: string;
    value?: JsonSettingValue | ThemeComponent;
  };
}

interface Change {
  timestamp: string;
  delta: Delta;
}

interface State {
  prevAvailable: boolean;
  nextAvailable: boolean;
}

class EditorHistoryImpl {
  private state: State = { nextAvailable: false, prevAvailable: false };

  private lastInsertIndex = -1;

  private changelog: Change[] = [];

  private lastInsertHash = "";

  private currentIndex = this.lastInsertIndex;

  private jsonTheme: JsonThemeObject | null = null;

  private initialTheme: ThemeConfiguration | null = null;

  init(jsonTheme: JsonThemeObject) {
    this.jsonTheme = jsonTheme;
    if (!this.initialTheme) {
      this.initialTheme = this.jsonTheme.getTheme();
    }
  }

  public async insertSaveSetting(
    uniqueId: string,
    setting: string,
    value: JsonSettingValue,
  ) {
    const delta: Delta = {
      type: "saveSetting",
      parameters: {
        uniqueId,
        setting,
        value,
      },
    };

    await this.saveChanges(delta);
  }

  public async insertRemoveComponent(uniqueId: string) {
    const delta: Delta = {
      type: "removeComponent",
      parameters: {
        uniqueId,
      },
    };

    await this.saveChanges(delta);
  }

  public async insertAddComponent(uniqueId: string, value: ThemeComponent) {
    const delta: Delta = {
      type: "addComponent",
      parameters: {
        uniqueId,
        value,
      },
    };

    await this.saveChanges(delta);
  }

  private getTimestampDiff(timestampA: string, timestampB: string) {
    const timestampADate = new Date(timestampA);
    const timestampBDate = new Date(timestampB);
    return timestampADate.getTime() - timestampBDate.getTime();
  }

  private updateState() {
    this.state.prevAvailable = this.currentIndex > -1;
    this.state.nextAvailable = this.currentIndex < this.changelog.length - 1;
    this.jsonTheme?.notifyObservers();
  }

  public async prev() {
    if (this.state.prevAvailable && this.initialTheme) {
      this.currentIndex -= 1;
      this.jsonTheme?.setTheme(this.initialTheme, { silent: true });
      await this.refresh();
      this.updateState();
    }
  }

  public async next() {
    if (this.state.nextAvailable) {
      this.currentIndex += 1;
      await this.refresh();
      this.updateState();
    }
  }

  private async saveChanges(delta: Delta): Promise<void> {
    const timestamp = new Date().toISOString();
    const currentHash = simpleHash(jsonStringifySafe(delta) || "");
    if (currentHash === this.lastInsertHash) {
      return;
    }
    this.changelog.length = this.currentIndex + 1;
    const change: Change = { timestamp, delta };
    this.lastInsertIndex = this.changelog.push(change) - 1;
    this.currentIndex = this.lastInsertIndex;
    this.lastInsertHash = currentHash;
    this.updateState();
  }

  public async refresh(theme: ThemeConfiguration | null = this.initialTheme) {
    if (this.currentIndex === -1 && theme) {
      this.jsonTheme?.setTheme(theme);
      return;
    }

    if (!this.changelog[this.currentIndex]) {
      console.error("Cannot get current data from index", this.currentIndex);
      return;
    }

    this.changelog.forEach(({ timestamp, delta }) => {
      const timestampDiff = timestamp
        ? this.getTimestampDiff(
            this.changelog[this.currentIndex].timestamp,
            timestamp,
          )
        : Infinity;
      if (timestampDiff >= 0) {
        this.mergeDelta(delta);
      }
    });
  }

  private mergeDelta({ parameters, type }: Delta) {
    if (type === "saveSetting") {
      this.jsonTheme?.saveSetting(
        parameters.uniqueId || "",
        parameters.setting || "",
        parameters.value,
        { silent: true, ignoreHistory: true },
      );
    }
    if (type === "addComponent") {
      this.jsonTheme?.addComponent(
        parameters.uniqueId || "",
        parameters.value,
        {
          silent: true,
          ignoreHistory: true,
        },
      );
    }
    if (type === "removeComponent") {
      this.jsonTheme?.removeComponent(parameters.uniqueId || "", {
        silent: true,
        ignoreHistory: true,
      });
    }
  }

  public getState() {
    return this.state;
  }
}

export const EditorHistory = new EditorHistoryImpl();
