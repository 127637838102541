import { useEffect } from "react";

export const SSR = typeof window === "undefined";

export const isBuiltfirstSubdomain = () => {
  if (!SSR) {
    const currentHostname = window.location.hostname;

    return currentHostname.includes("builtfirst.com");
  }
};

function inIframe() {
  try {
    if (SSR) {
      return false;
    }
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export const iframe = inIframe();
export const getDomainNameFromUrl = () => {
  const fullUrl = window.location.href;
  const subdomain = fullUrl.split(".")[0];
  const parsedSubdomain = subdomain.substring(subdomain.lastIndexOf("/") + 1);

  return parsedSubdomain;
};

export function useScreenshotPreviewElementEffect() {
  useEffect(() => {
    const element = document.getElementById("app-content");
    const images = document.querySelectorAll("img");
    let timeoutAfterImages: NodeJS.Timeout;
    let timeoutLimit: NodeJS.Timeout;

    function setContentLoaded() {
      if (element) {
        element.id = "app-content-loaded";
      }
      clearTimeout(timeoutAfterImages);
      clearTimeout(timeoutLimit);
    }

    const handleImageLoad = () => {
      const allImagesLoaded = Array.from(images).every((img) => img.complete);
      if (allImagesLoaded && element) {
        timeoutAfterImages = setTimeout(setContentLoaded, 3000);
      }
    };

    handleImageLoad();
    images.forEach((image) => {
      if (!image.complete) {
        image.onload = handleImageLoad;
      }
    });

    timeoutLimit = setTimeout(setContentLoaded, 8000);

    return () => {
      images.forEach((image) => {
        image.onload = null;
      });
      clearTimeout(timeoutAfterImages);
      clearTimeout(timeoutLimit);
    };
  }, []);
}
