import { Button, ButtonProps, styled } from "@mui/material";
import { ReactNode, Ref, forwardRef } from "react";
import { useCapitalization } from "../../builder/Theme";

export const TextButton = styled(
  forwardRef(
    (
      props: ButtonProps & {
        children?: ReactNode;
        target?: string;
      },
      ref: Ref<HTMLButtonElement>,
    ) => <Button variant="text" ref={ref} {...props} />,
  ),
)<{ textColor?: string }>(({ theme, textColor }) => {
  const { buttons } = theme.settings;
  const textTransform = useCapitalization(buttons?.capitalization);
  return { textTransform, color: textColor || theme.palette.text.default };
});
