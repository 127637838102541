export const isLocalStorageAvailable = () => {
  const testIfLocalStorageIsAvailable = "testIfLocalStorageIsAvailable";
  try {
    localStorage.setItem(
      testIfLocalStorageIsAvailable,
      testIfLocalStorageIsAvailable,
    );
    localStorage.removeItem(testIfLocalStorageIsAvailable);
    return true;
  } catch (e) {
    return false;
  }
};
