function isLocalStorageAvailable() {
  const testIfLocalStorageIsAvailable = "testIfLocalStorageIsAvailable";
  try {
    localStorage.setItem(
      testIfLocalStorageIsAvailable,
      testIfLocalStorageIsAvailable,
    );
    localStorage.removeItem(testIfLocalStorageIsAvailable);
    return true;
  } catch (e) {
    return false;
  }
}

// TODO: remove localStorage types so we only use this, and then we dont ask if localstorage every time we need to use it
class LocalStorageWrapper {
  private available = isLocalStorageAvailable();

  public isAvailable() {
    return this.available;
  }

  public clear() {
    if (this.isAvailable()) {
      localStorage.clear();
    }
  }

  public getItem(key: string) {
    if (this.isAvailable()) {
      return localStorage.getItem(key);
    }
  }

  public removeItem(key: string) {
    if (this.isAvailable()) {
      localStorage.removeItem(key);
    }
  }

  public setItem(key: string, value: string) {
    if (this.isAvailable()) {
      localStorage.setItem(key, value);
    }
  }
}

export const localStorageWrapper = new LocalStorageWrapper();
